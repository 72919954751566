import { UploadedFileModel } from "../components/file-uploader/file-uploader.component";

export class DetailDTO {
  public image: string;
  public dateFrom: Date;
  public dateTo: Date;
  public categoryName: string | null;
  public name: string;
  public locationString: string | null;
  public description: string;
  public files: UploadedFileModel[];
  public locationLat?: number | null;
  public locationLng?: number | null;
  public id: number;
  public ticketId: number;
  public allDay: boolean;
  public summary: string;
}
