import { Component, Input, input, type OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { EsdevenimentDTO } from 'src/app/DTO/EsdevenimentDTO';
import { TicketPriceDTO } from 'src/app/DTO/TicketPriceDTO';
import { CommunityService } from 'src/app/services/community/community.service';
import { EventCardDTO } from 'src/app/DTO/EventCardDTO';
import { TicketMinDTO } from 'src/app/DTO/min/TicketMinDTO';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent implements OnInit {

  ticket = input<TicketMinDTO>(new TicketMinDTO());
  event = input<EsdevenimentDTO>(new EsdevenimentDTO());
  isCustomCommunity = input<boolean>(false);

  public prices: TicketPriceDTO[];
  public isFree: boolean;
  public sameDay: boolean;
  public duration: number;
  public defaultImg: string;
  public model: EventCardDTO;
  public buttonText: string;

  private start: DateTime;
  private end: DateTime;

  constructor(private communityService: CommunityService) { }

  ngOnInit(): void {
    this.defaultImg = this.communityService.defaultImg;

    if(this.ticket().id) {
      this.setTicketCard();
    } else if(this.event) {
      this.setEventCard();
    }
  }

  private setTicketCard(): void {
    const {
      images,
      eventImage,
      saleDateFrom,
      saleDateTo,
      title,
      id,
      prices,
      categoryName,
      categoryId,
      isOpened
    } = this.ticket();

    this.start = DateTime.fromISO(saleDateFrom);
    this.end = DateTime.fromISO(saleDateTo);

    this.model = {
      image: !images || images.length === 0 ? eventImage : images[0].path,
      name: title,
      ticketId: id,
      allDay: false,
      categoryId,
      categoryName,
      dateFrom: this.start.toJSDate(),
      dateTo: this.end.toJSDate(),
      disabled: !isOpened
    };

    if(!isOpened) {
      const now = DateTime.now();

      if (this.start?.isValid && now <= this.start) {
        this.buttonText = 'Programmed';
      }

      if (this.end?.isValid && now >= this.end) {
        this.buttonText = 'Ended';
      }
    }

    this.duration = this.getDuration();
    this.isFree = this.getIsFree(prices);
  }

  private setEventCard(): void {
    const {
      dateFrom,
      dateTo,
      ticketPrices,
      name,
      image,
      allDay,
      categoryId,
      categoryName
    } = this.event();

    this.start = DateTime.fromJSDate(dateFrom);
    this.end = DateTime.fromJSDate(dateTo);

    this.model = {
      image,
      name,
      dateFrom,
      dateTo,
      allDay,
      categoryName,
      categoryId,
      disabled: false
    };

    if(dateTo) {
      this.checkIsSameDay();
    }

    this.isFree = this.getIsFree(ticketPrices);
  }

  private getDuration(): number {
    const diff = this.end.diff(this.start).as('days');
    return Math.trunc(diff);
  }

  private getIsFree(prices: TicketPriceDTO[]): boolean {
    return prices.every(x => x.totalWithFee == 0);
  }

  private checkIsSameDay(): void {
    this.sameDay = this.start.hasSame(this.end, 'day');

    if (!this.sameDay) {
      this.duration = this.getDuration();
    }
  }
}
