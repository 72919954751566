<div class="container">
    @for (opt of options; track opt.value; let idx = $index, e = $even) {
        <div class="option-card" (click)="select(opt)" 
            [class.selected]="opt.checked" 
            [class.disabled]="opt.disabled"
            [pTooltip]="opt.disabled ? opt.disabledTooltip : null"
            tooltipPosition="top" 
            tooltipStyleClass="cards-option-tooltip"
            >

            @if (multiple){
                <p-checkbox [(ngModel)]="opt.checked" [binary]="true" />
            }

            @if (opt.iconClass || opt.iconPath){
                <div class="icon">
                    @if (opt.iconClass){
                        <i [class]="opt.iconClass"></i>
                    }
                    @if (opt.iconPath){
                        <img [src]="opt.iconPath">
                    }
                </div>
            }
            <div>
                <h3>{{opt.title}}</h3>
                
                <div [innerHTML]="opt.description"></div>

                <div class="footer" [innerHTML]="opt.footer"></div>
            </div>
        </div>
    }
</div>