import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { CustomFieldTextComponent, CustomFieldCheckboxComponent,  } from './fields/fields'
import { CustomFieldTextareaComponent } from "./fields/components/textarea/textarea.component";
import { CustomFieldNumericComponent } from "./fields/components/numeric/numeric.component";
import { CustomFieldNif } from "./fields/components/nif/nif.component";
import { CustomFieldIbanComponent } from "./fields/components/iban/iban.component";
import { CustomFieldCalendarComponent } from "./fields/components/calendar/calendar.component";
import { CustomFieldDropdownComponent } from "./fields/components/dropdown/dropdown.component";
import { CustomFieldFileComponent } from "./fields/components/file/file.component";
import { CustomFieldPhoneComponent } from "./fields/components/phone/phone.component";
import { CustomFieldEmailComponent } from "./fields/components/email/email.component";
import { CustomFieldsConstants } from 'src/app/constants/CustomFieldsConstants';
import { CustomFieldDTO } from 'src/app/DTO/CustomFieldDTO';

@Component({
  selector: 'app-custom-field-input',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFieldInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomFieldInputComponent),
      multi: true
    }
  ],
  templateUrl: './custom-field-input.component.html',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomFieldCheckboxComponent,
    CustomFieldTextComponent,
    CustomFieldTextareaComponent,
    CustomFieldNumericComponent,
    CustomFieldNif,
    CustomFieldIbanComponent,
    CustomFieldCalendarComponent,
    CustomFieldDropdownComponent,
    CustomFieldFileComponent,
    CustomFieldPhoneComponent,
    CustomFieldEmailComponent
],
  styleUrls: ['./custom-field-input.component.scss']
})
export class CustomFieldInputComponent implements OnInit, ControlValueAccessor{

  @ViewChild('input') input!: NgModel;

  @Input()
  public customField: CustomFieldDTO | null;
  
  @Input()
  public readonly: boolean;

  public internalValue: any;
  public show = false;
  public oldValue: string;
  public InputTypes = CustomFieldsConstants.InputTypes;
  public DropdownTypes = CustomFieldsConstants.DropdownTypes;

  @Output() priceChanged = new EventEmitter<any>();
  onChange: (_: string) => void = (_: string) => { };

  constructor() { }

  ngOnInit(): void { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void { }

  writeValue(val: string): void {
    this.internalValue = val;
  }

  validate({ value }: FormControl) {
    // Required
    if (!this.readonly && this.customField.required && (value === null || ((typeof value === 'string' || Array.isArray(value)) && value.length === 0))) {
      return {
        required: true
      }
    }

    // Any errors
    if(this.input) {
      return this.input.control.errors;
    }

    return false;
  }

  updateChanges(value: string, dropdownType: string = null) {
    if (this.customField.type === this.InputTypes.DROPDOWN && (dropdownType && dropdownType === this.DropdownTypes.TABLE)) {
      this.priceChanged.emit();
    }

    if (this.customField) {
      this.customField.value = value;
      this.onChange(this.customField.value);
    }
  }
}
