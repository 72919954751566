<div class="reservation-detail-wrapper">
    <div class="container">
        <form [formGroup]="form" #reservationSearch="ngForm" id="reservationSearch" class="mt-5" *ngIf="!viewResend">
            <h2 class="fw-600 mb-3">{{ 'PRIORAPPOINTMENTDETAIL.searchAppointmentTitle' | translate }}</h2>

            <div class="row">
                <div class="col-12 col-md-5 mb-3">
                    <label class="mb-2 font-w-600"
                           [ngClass]="form.get('code')?.hasError('required') && myForm?.submitted ? 'text-danger' : ''">
                        {{ 'PRIORAPPOINTMENTDETAIL.reservationCodeLabel' | translate }}
                    </label>

                    <br>
                    <input type="text" class="w-100" pInputText [(ngModel)]="codeSearch" id="name" formControlName="code" />
                    <p class="text-danger mt-1" *ngIf="form.get('code')?.hasError('required') && myForm?.submitted">
                        {{ 'PRIORAPPOINTMENTDETAIL.reservationCodeError' | translate }}
                    </p>
                    <p class="mt-1 mb-0 cursor-pointer" (click)="viewResend = true" [style.color]="community?.color1">Recuperar el codi</p>

                </div>

                <div class="col-12 col-md-5 mb-3">
                    <label class="mb-2 font-w-600"
                           [ngClass]="form.get('dni')?.hasError('required') && myForm?.submitted ? 'text-danger' : ''">
                        CIF / DNI / NIE *
                    </label>
                    <br>
                    <input type="text" class="w-100" pInputText [(ngModel)]="dniSearch" id="dni" formControlName="dni" />

                    <p class="text-danger mt-1" *ngIf="form.get('dni')?.hasError('required')  && myForm?.submitted">
                        {{ 'PRIORAPPOINTMENTDETAIL.reservationDateError' | translate }}
                    </p>
                </div>
                <div class="col-12 col-md-2 mb-3 d-flex align-items-end flex-column">
                    <label class="mb-2 font-w-600">
                    </label>
                    <br>
                    <button class="btn bg-white me-2 rounded border"
                            style="padding:0.75rem 1.5rem" (click)="search()"
                            type="submit" form="reservationSearch">
                        <span><i class="fa-solid fa-search me-2"></i>{{ 'PRIORAPPOINTMENTDETAIL.searchButton' | translate }}</span>
                    </button>
                    <p></p>
                </div>
            </div>
        </form>


        <form [formGroup]="formResend" #resendCode="ngForm" id="resendCode" class="mt-5" *ngIf="viewResend">
            <h2 class="fw-600 mb-3">{{'RecoverCode' | translate}}</h2>

            <div class="row">
                <div class="col-12 col-md-5 mb-3">
                    <label class="mb-2 font-w-600"
                           [ngClass]="formResend.get('mail')?.hasError('required') && myFormResend?.submitted ? 'text-danger' : ''">
                        Email
                    </label>

                    <br>
                    <input type="text" class="w-100" pInputText [(ngModel)]="resendCodeObject.mail" id="mail" formControlName="mail" />
                    <p class="text-danger mt-1" *ngIf="formResend.get('mail')?.hasError('required') && myFormResend?.submitted">
                        {{ 'PRIORAPPOINTMENTDETAIL.reservationCodeError' | translate }}
                    </p>
                    <p class="mt-1 mb-0 cursor-pointer" (click)="viewResend = false" [style.color]="community?.color1"> <i class="fa fa-arrow-left"></i> Volver </p>
                </div>

                <div class="col-12 col-md-5 mb-3">
                    <label class="mb-2 font-w-600"
                           [ngClass]="formResend.get('dniResend')?.hasError('required') && myFormResend?.submitted ? 'text-danger' : ''">
                        CIF / DNI / NIE *
                    </label>
                    <br>
                    <input type="text" class="w-100" pInputText [(ngModel)]="resendCodeObject.dni" id="dniResend" formControlName="dniResend" />

                    <p class="text-danger mt-1" *ngIf="formResend.get('dniResend')?.hasError('required')  && myFormResend?.submitted">
                        {{ 'PRIORAPPOINTMENTDETAIL.reservationDateError' | translate }}
                    </p>
                </div>
                <div class="col-12 col-md-2 mb-3 d-flex align-items-end flex-column">
                    <label class="mb-2 font-w-600">
                    </label>
                    <br>
                    <button class="btn bg-white me-2 rounded border"
                            style="padding:0.75rem 1.5rem" (click)="resendEmailCode()"
                            type="submit" form="resendCode">

                            <span *ngIf="!loadButton"><i class="fa-solid fa-search me-2"></i>{{'Recover' | translate}}</span>
                            <app-spinner *ngIf="loadButton" [size]="'s'" [color]="'#555555'"></app-spinner>
                    </button>
                    <p></p>
                </div>
            </div>
        </form>


        <p-skeleton *ngIf="isLoading" styleClass="mb-2" height="120px"></p-skeleton>
        <div *ngIf="tryFind">
            <div class="alert alert-secondary" role="alert">
                <span>{{ 'PRIORAPPOINTMENTDETAIL.noReservationFound' | translate }}</span>
            </div>
        </div>
        <app-finish-reservation *ngIf="reservation"
                                [itsUpdating]="true"
                                [community]="community"
                                [reservation]="reservation">
        </app-finish-reservation>
    </div>
</div>
