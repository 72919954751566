import { Component, effect, OnInit, signal, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize, firstValueFrom, Observable, skip } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { CommunityService } from 'src/app/services/community/community.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { TicketMinDTO } from 'src/app/DTO/min/TicketMinDTO';
import { TicketFilterDTO } from 'src/app/DTO/filters/TicketFilterDTO';
import { Paginator } from 'primeng/paginator';
import { MetadataService } from 'src/app/services/metadata.service';
import { Functions } from 'src/app/helpers/Functions';

@Component({
  selector: 'app-ticketing',
  templateUrl: './ticketing.component.html',
  styleUrl: './ticketing.component.scss'
})
export class TicketingComponent implements OnInit {

   @ViewChild('paginatorElement', { static: true }) paginatorElement: Paginator;

  public tickets = signal<TicketMinDTO[]>([]);
  public isIframe = false;
  public isCustom = false;
  public title: string;
  public $loading = signal<boolean>(true);
  public $optionsLoaded = signal<boolean>(false)
  public first = 0;
  public rows = 9;
  public page = 1;
  public collectionSize = 1;
  public pageSize = 9;
  public categoryParam = null;

  // Filters
  public filtersOptions = {};
  public selectedFilters = signal({
    categories: [],
    free: null,
  });


  private communityCode: string;
  private customCommunities = [
    'tortola-de-henares'
  ];

  constructor(
    private api: ApiService,
    private actRoute: ActivatedRoute,
    private themeService: ThemeService,
    private communityService: CommunityService,
    private translate: TranslateService,
    private metadataService: MetadataService,
  ) {
    effect(() => {
      const filters = this.selectedFilters();
      this.countTickets(filters);
      this.updateCurrentPage(0)
    })
  }

  async ngOnInit() {
    this.communityCode = this.actRoute.snapshot.params['community'];

    this.categoryParam = this.actRoute.snapshot.queryParamMap.get('category');

    const community = await firstValueFrom(this.communityService.Get());

    this.metadataService.updateMetadata({
      title: Functions.decode(community.name) + " - " + this.translate.instant('TICKETING.Name'),
      description: this.translate.instant('NEWS.NewsFrom') + Functions.decode(community.name)
    });

    if(this.categoryParam) {
      this.selectedFilters.set({
        categories: [parseInt(this.categoryParam)],
        free: null
      });
    }

    this.init();
  }

  public pageChange(event) {
    this.first = event.first;
    this.rows = event.rows;

    this.getTickets(this.selectedFilters())
      .subscribe(tickets => {
        this.setTickets(tickets);
      })
  }

  private getTickets(filters: TicketFilterDTO): Observable<TicketMinDTO[]> {
    this.$loading.set(true);
    try {
      return this.api
        .getFilteredTickets(this.communityCode, filters, this.rows, this.first)
        .pipe(finalize(() => this.$loading.set(false)));
    } catch (err) {
      console.error('ERROR AL CARGAR TICKETS --> ', err);
      this.tickets.set([]);
    }
  }

  private countTickets(filters: TicketFilterDTO) {
    this.api
      .countTickets(this.communityCode, filters)
      .subscribe((res) => {
        this.collectionSize = res;
      })
  }

  private setTickets(tickets: TicketMinDTO[]) {
    this.tickets.set(tickets);
  }

  private async init() {
    this.isIframe = await this.themeService.getIsIframe();

    this.isCustom = this.customCommunities.includes(this.communityCode);

    this.title = this.translate.instant('Events');
    this.communityService.GetModulesDict().subscribe((res) => {
      if (res && res["TICKETING"]) {
        this.title = res["TICKETING"].name;
      }
    });

    // Load filters options
    this.filtersOptions = await this.setFiltersOptions();
    this.$optionsLoaded.set(true);
  }

  private async setFiltersOptions() {
    const filters = {
      categories: await this.getCategoriesOptions(),
      prices: this.setPricesOptions()
    }

    return filters;
  }

  private async getCategoriesOptions() {
    const { id } = await firstValueFrom(this.communityService.Get());

    try {
      const categories = await firstValueFrom(this.api.getCategoriesByModule(id, 'TICKETING'));
      console.log('CATEGORIES --> ', categories);
      return categories;
    } catch (err) {
      console.error('ERROR AL CARGAR CATEGORIAS --> ', err);
      return [];
    }
  }

  private setPricesOptions() {
    return [
      { value: true, label: this.translate.instant('Free') },
      { value: false, label: this.translate.instant('NotFree') }
    ]
  }

  private updateCurrentPage(currentPage: number): void {
    this.first = 0;
    setTimeout(() => this.paginatorElement.changePage(0));
  }
}
