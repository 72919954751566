
        <div class="backButton mb-4">
            <h3 class="text-l font-weight-bolder" *ngIf="communityCode" [routerLink]="[ '/', communityCode , 'news']" queryParamsHandling="merge"><fa-icon [icon]="faArrowLeft"></fa-icon> {{ 'GoToList' | translate }}</h3>
        </div>

        <div class="newsContent" *ngIf="new">
            <div class="newsContentElements">
              <div class="imageBox">
                  <img [src]="new.image | imageUrl | async">
              </div>
              <div class="p-4">
                <div class="date ml-0 pl-0">{{new.publishDate | date:'dd/MM/YYYY' }}</div>
                <h1 class="ml-0 pl-0">{{new.title}}</h1>
                <p class="new-inner-body ml-0 pl-0" [innerHTML]="new.body"></p>
              </div>
            </div>
        </div>
