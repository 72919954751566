import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, type OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { CardSelectOption } from './CardSelectOption';

@Component({
  selector: 'app-cards-option-select',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TooltipModule,
    CheckboxModule
  ],
  templateUrl: './cards-option-select.component.html',
  styleUrl: './cards-option-select.component.scss',
})
export class CardsOptionSelectComponent implements OnInit {

  @Input()
  public options: CardSelectOption[];

  @Input()
  public multiple = false;

  @Output()
  public onSelect = new EventEmitter<any>();
  @Output()
  public onDisabledClick = new EventEmitter<any>();
  public selected: any;

  ngOnInit(): void { 

  }

  select(opt: any) {

    if (opt.disabled){
      this.onDisabledClick?.emit(opt);
      return;
    }

    if (this.multiple){
      opt.checked = !opt.checked;

      this.selected = (this.selected ?? []);

      if (opt.checked){
        this.selected.push(opt);
      } else {
        this.selected = this.selected.filter(x => x.value != opt.value);
      }
    } else {
      if (this.selected) this.selected.checked = false;
      this.selected = opt;
      this.selected.checked = true;
    }
    
    this.onSelect.emit(this.selected);
  }

}
