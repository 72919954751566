import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { InputMaskModule } from "primeng/inputmask";
import { isValid, printFormat } from 'iban-ts';

@Component({
  selector: 'app-custom-field-iban',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFieldIbanComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomFieldIbanComponent),
      multi: true
    }
  ],
  templateUrl: './iban.component.html',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputMaskModule,
],
  styleUrls: ['../../styles/fields.scss']
})
export class CustomFieldIbanComponent implements OnInit, ControlValueAccessor{

  @Input()
  public readonly: boolean;
  public formattedIban: string;
  public internalValue: string;

  onChange: (_: string) => void = (_: string) => { };

  constructor() { }

  ngOnInit(): void { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(): void { }

  writeValue(val: string): void {
    if(this.readonly && val) {
      this.formattedIban = printFormat(val);
    }
    this.internalValue = val;
  }

  validate({ value }: FormControl) {
    if (value) {
      // IBAN
      console.log(isValid(value));
      if (!isValid(value)) {
        return {
          format: true
        }
      }
    }

    return false;
  }

  updateChanges(value: string) {
    this.onChange(value);
  }
}
