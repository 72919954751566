<ng-container *ngIf="isLoading">
    <div class="ticket-container" [style]="'--corp-color-1: ' + corpColor+ ';'">
        <div class="ticket mb-3">
            <div class="row justify-content-between mt-1 mb-1">
                <div class="price-info col-5 col-md-5">
                    <p-skeleton styleClass="w-50 mt-1"></p-skeleton>
                    <p-skeleton styleClass="w-25 mt-2"></p-skeleton>
                </div>
                <div class="col-md-3 col-5">
                    <p-skeleton styleClass="w-100" height="50px"></p-skeleton>
                    <p-skeleton styleClass="w-100" height="50px"></p-skeleton>
                </div>
            </div>
            <div class="row justify-content-between mt-1 mb-1">
                <div class="price-info col-5 col-md-5">
                    <p-skeleton styleClass="w-50 mt-1"></p-skeleton>
                    <p-skeleton styleClass="w-25 mt-2"></p-skeleton>
                </div>
                <div class="col-md-3 col-5">
                    <p-skeleton styleClass="w-100" height="50px"></p-skeleton>
                    <p-skeleton styleClass="w-100" height="50px"></p-skeleton>
                </div>
            </div>
        </div>
        <div class="total-box">
            <p-skeleton styleClass="w-50"></p-skeleton>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!isLoading">
    <div class="ticket-container pb-3" [style]="'--corp-color-1: ' + corpColor + ';'">
        <div class="ticket mb-1">
            <ng-container *ngIf="availableTickets != null">
                <div *ngIf="availableTickets < 10 && availableTickets > 1" class="mb-3 text-red">
                  {{ 'INSCRIPTIONS.OnlyXPlacesLeft' | translate: { n: availableTickets } }}
                </div>
                <div *ngIf="availableTickets < 10 && availableTickets == 1" class="mb-3 text-red">
                  {{ 'TICKETS.Only1PlaceLeft' | translate }}
                </div>
                <div *ngIf="availableTickets <= 0" class="mb-3 text-red">
                    {{'Modules.Ticketing.Exceeded' | translate}}
                </div>
              </ng-container>

            <div class="buy-section mt-1 mb-1" *ngFor="let price of ticket.prices">
                <div class="price-info d-flex flex-column gap-1">
                    <span class="fw-600">{{ price.name }}</span>
                    <span *ngIf="price.total != 0" class="price-text">{{ price.total | currency:'€' }}</span>
                    <span *ngIf="price.total == 0" class="price-text">{{ 'Free' | translate }}</span>

                    @if(availableByPrice[price.id]?.freePlaces !== null) {
                      <div class="text-red">
                        @if(availableByPrice[price.id].freePlaces > 1) {
                          {{ 'INSCRIPTIONS.MAX_SALES_CUSTOM_MESSAGE' | translate: { value: availableByPrice[price.id].freePlaces } }}
                        } @else if (availableByPrice[price.id].freePlaces === 1){
                          {{ 'TICKETS.Only1PlaceLeft' | translate }}
                        } @else if (availableByPrice[price.id].freePlaces <= 0) {
                          {{'Modules.Ticketing.Exceeded' | translate}}
                        }
                      </div>
                    }
                </div>
                <div>
                  <p-inputNumber
                      styleClass="input-width"
                      class="input-width"
                      (ngModelChange)="onChangeQty($event, price)"
                      [(ngModel)]="price.qty"
                      [showButtons]="true"
                      buttonLayout="horizontal"
                      inputId="horizontal"
                      spinnerMode="horizontal"
                      [step]="1"
                      incrementButtonIcon="pi pi-plus"
                      decrementButtonIcon="pi pi-minus"
                      [min]="0"
                      [max]="maxPerPrice[price.id]"
                      tabindex="-1"
                    />
                </div>
            </div>
        </div>
        <div class="total-price pb-3">
            <span>
                {{ 'Total' | translate }}:
            </span>
            <strong>{{ total | currency:'€' }}</strong>
        </div>
    </div>
</ng-container>
