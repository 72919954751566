<ng-container *ngIf="!readonly">
  <input 
    type="email"
    pInputText 
    [ngModel]="internalValue"
    (ngModelChange)="updateChanges($event)"
    styleClass="w-100" />
</ng-container>

<ng-container *ngIf="readonly">
  <a href="mailto:{{ internalValue }}">
    <span>
      {{ internalValue }}
    </span>
  </a>
</ng-container>