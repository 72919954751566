<ng-container *ngIf="!readonly">
  <p-inputMask
    mask="aa99 9999 9999 99 9999999999"
    [ngModel]="internalValue" 
    (ngModelChange)="updateChanges($event)"
    placeholder="ES00 0000 0000 00 0000000000"
    styleClass="w-100"
  />
</ng-container>

<ng-container *ngIf="readonly">
  {{ formattedIban }}
</ng-container>