import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ReservationDTO} from "../../../DTO/PriorAppointment/ReservationDTO";
import {CommunityDTO} from "../../../DTO/CommunityDTO";
import {Functions} from "../../../helpers/Functions";
import {ApiService} from "../../../services/api.service";
import {ActivatedRoute} from "@angular/router";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {CommunityService} from "../../../services/community/community.service";
import {MetadataService} from "../../../services/metadata.service";
import {FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import Swal from "sweetalert2";
import {FindReservationDTO, ResendCode} from "../../../DTO/PriorAppointment/PeriodsAndHoursDto";
import {DateTime} from "luxon";

@Component({
  selector: 'app-reservation-detail',
  templateUrl: './reservation-detail.component.html',
  styleUrl: './reservation-detail.component.scss'
})
export class ReservationDetailComponent implements OnInit{
  public reservation: ReservationDTO;
  public community: CommunityDTO;
  public findReservationDTO: FindReservationDTO;
  public resendCodeObject: ResendCode = new ResendCode();
  public codeSearch: string;
  public dniSearch: string;
  public tryFind = false;
  isLoading = false;
  loadButton = false;
  events: any[];
  communityName = "";
  form: FormGroup;
  formResend: FormGroup;
  viewResend = false;
  @ViewChild('reservationSearch') myForm: NgForm;
  @ViewChild('resendCode') myFormResend: NgForm;
  constructor(private apiService: ApiService,
              private fb: FormBuilder,
              private actRoute: ActivatedRoute,
              public activeModal: NgbActiveModal,
              private translate: TranslateService,
              private communityService: CommunityService,
              private metadataService: MetadataService) {

  }

  ngOnInit(): void {
    this.isLoading = true;
    this.initializeForm();
    this.communityName = this.actRoute.snapshot.params['community'];
    this.communityService.Get().subscribe(res=>{
      this.community = res;
      if (this.community ) {
        this.metadataService.updateMetadata({
          title: Functions.decode( this.community.name) + " - " + this.translate.instant('PRIORAPPOINTMENT.title'),
        });
      }
    });
    this.isLoading = false;
  }
  private initializeForm(): void {

    this.formResend = this.fb.group({
      mail: ['', Validators.required],
      dniResend: ['', Validators.required]
    });

    this.form = this.fb.group({
      code: ['', Validators.required],
      dni: ['', Validators.required]
    });

  }
  public search(){
    if(this.form.valid){
      this.isLoading = true;
      this.tryFind = false;
      this.findReservationDTO = new FindReservationDTO();
      this.findReservationDTO.codeIdentifier = this.codeSearch;
      this.findReservationDTO.dni = this.dniSearch
      this.apiService.getReservationDetail(this.community.code, this.findReservationDTO).toPromise()
          .then((res) => {

            if(res){
              console.log(res);
              this.reservation = res;
            }else{
              this.reservation = null;
              this.tryFind = true;
            }
            this.isLoading = false;
          });
    }
  }
  public resendEmailCode(){
     if(this.formResend.valid){
       this.loadButton = true;
       this.apiService.resendCode(this.community.code, this.resendCodeObject).toPromise()
           .then((res) => {
             Swal.fire({
               title: this.translate.instant('GENERIC.good'),
               icon: 'success',
               text: this.translate.instant('RecoverMailPrior'),
               confirmButtonText: this.translate.instant('PRIORAPPOINTMENT.genericAccept')
             });
             this.loadButton = false;
           });
     }
  }
}
