import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { DateTime } from "luxon";
import { ControlValueAccessor, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from "primeng/calendar";
import { CommonModule } from "@angular/common";

@Component({
  selector: 'app-custom-field-calendar',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFieldCalendarComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomFieldCalendarComponent),
      multi: true
    }
  ],
  templateUrl: './calendar.component.html',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
],
  styleUrls: ['../../styles/fields.scss']
})
export class CustomFieldCalendarComponent implements OnInit, ControlValueAccessor{

  @Input()
  public hasTime: boolean;
  @Input()
  public readonly: boolean;

  public dateFormat: string = 'dd/MM/yyyy';
  public internalValue: any;
  public dateToFormat: Date;

  onChange: (_: string) => void = (_: string) => { };

  constructor() { }

  ngOnInit(): void { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(): void { }

  writeValue(val: string): void {
    if(this.hasTime) {
      this.dateFormat = 'dd/MM/yyyy HH:mm'
    }

    this.internalValue = this.modelFromString(val);
  }

  validate() {
    return false;
  }

  updateChanges(value: any) {
    const date = this.modelToString(value);
    this.onChange(date);
  }

  private modelToString(value: any) {
    if (!value) { return null; }
    return DateTime.fromJSDate(value).toISO();
  }

  private modelFromString(value: string): any {
    if (!value) { return null; }
    return DateTime.fromISO(value).toJSDate();
  }
}
