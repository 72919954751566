<ng-container *ngIf="!readonly">
  <p-inputNumber 
    [minFractionDigits]="0"
    [maxFractionDigits]="0"
    [useGrouping]="false"
    styleClass="w-100"
    [ngModel]="internalValue"
    (ngModelChange)="updateChanges($event)">
  </p-inputNumber>
</ng-container>

<ng-container *ngIf="readonly">
  <span>{{ internalValue }}</span>
</ng-container>