<form [formGroup]="form">
    <div class="form-container mt-4">
        <div class="input-box">
          <label for="name" htmlFor="name">{{ 'Name' | translate}} *</label>
                <input
                    name="name"
                    id="name"
                    formControlName="name"
                    class="form-control custom-input" type="text"
                    [class.error-input]="submitAttempted  && form.get('name').hasError('required')" pInputText>
            <div class="error-message" *ngIf="submitAttempted  && form.get('name').hasError('required')">
                <span style="color: red">{{ 'RequiredField' | translate }}</span>
            </div>
        </div>
        <div class="input-box">
          <label for="surname" htmlFor="surname">{{ 'Surnames' | translate}} *</label>

                <input
                    name="surname"
                    id="surname"
                    formControlName="surname"
                    class="form-control custom-input" type="text"
                    [class.error-input]="submitAttempted  && form.get('surname').hasError('required')" pInputText>
            <div class="error-message" *ngIf="submitAttempted  && form.get('surname').hasError('required')">
                <span style="color: red">{{ 'RequiredField' | translate }}</span>
            </div>
        </div>
        <div class="input-box">
          <label for="mail" htmlFor="mail">{{ 'EMail' | translate}} *</label>

                <input
                    name="mail"
                    id="mail"
                    formControlName="mail"
                    class="form-control custom-input" type="text"
                    [class.error-input]="submitAttempted  && form.get('mail').hasError('required')" pInputText>
            <div class="error-message" *ngIf="submitAttempted  && form.get('mail').hasError('required')">
                <span style="color: red">{{ 'RequiredField' | translate }}</span>
            </div>
            <div class="error-message" *ngIf="submitAttempted  && form.get('mail').hasError('pattern')">
                <span style="color: red">{{ 'IncorrectFormat' | translate }}</span>
            </div>
        </div>
        <div class="input-box pb-2">
          <label for="phone" htmlFor="phone">{{ 'Phone' | translate}} *</label>
          <app-custom-field-phone
            name="phone"
            [class.error-input]="submitAttempted && form.get('phone').hasError('required')"
            formControlName="phone"/>
            <div class="error-message" *ngIf="submitAttempted  && form.get('phone').hasError('required')">
                <span style="color: red">{{ 'RequiredField' | translate }}</span>
            </div>
        </div>
        <div class="input-box pb-2" *ngFor="let field of customFields; let i = index;">
            <label>{{field.name}} <span *ngIf="field.required" class="required_asterisk"> *</span></label>
            <app-custom-field-input
                *ngIf="field"
                [customField]="field"
                formControlName="{{field.id.toString()}}"
                [class.error]="form.get(field.id.toString())?.invalid && submitAttempted "
            ></app-custom-field-input>
            <div *ngIf="form.get(field.id.toString())?.hasError('required') && submitAttempted ">
                <p class="mt-0 mb-3 text-danger">{{ 'RequiredField' | translate }}</p>
            </div>
        </div>

    </div>
    <div class="checkBox d-flex my-3 ps-1">
        <p-checkbox
                formControlName="acceptance"
                inputId="comercial"
                value="true"/>
        <label for="comercial" style="font-size:14px" class="ms-2">{{'EVENTS.userComercial' |translate}} {{community.name}}</label>
    </div>
</form>
