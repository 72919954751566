import { AttachedFileDTO } from "./AttachedFileDTO";
import { TicketPriceDTO } from "./TicketPriceDTO";
import {CustomFieldDTO} from "./CustomFieldDTO";
import {CustomFieldValueDTO} from "./CustomFieldValueDTO";


export class TicketDTO {
    id: number;

    eventId: number;

    communityCode: string;

    isActive: boolean;
    title: string;

    body: string;
    summary: string;
    saleDateFrom: string;
    saleDateTo: string;
    maxByUser?: number;
    maxSales?: number;

    prices: TicketPriceDTO[];
    images: any[];
    files: AttachedFileDTO[] = [];
    customFields: CustomFieldDTO[] = [];
    customFieldsValues: CustomFieldValueDTO[] = [];
    feeChargedTo: number;
    categoryId: number;
    categoryName: string;
    categoryColor: string;
}
