import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CommunityDTO} from "../../../DTO/CommunityDTO";
import {InscriptionsDTO} from "../../../DTO/InscriptionsDTO";
import {ImageUrlPipe} from "../../../pipes/image-url.pipe";
import {ApiService} from "../../../services/api.service";
import {CommunityService} from "../../../services/community/community.service";
import {Title} from "@angular/platform-browser";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {InscriptionFormComponent} from "../inscription-form/inscription-form.component";
import {CustomFieldValueDTO} from "../../../DTO/CustomFieldValueDTO";
import {DateTime} from "luxon";
import Swal from "sweetalert2";
import {TranslateService} from "@ngx-translate/core";
import { InscriptionsConstants } from '../constants';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { MetadataService } from 'src/app/services/metadata.service';
import { Functions } from 'src/app/helpers/Functions';
import {Subscription} from "rxjs";
import {InscriptionsService} from "../inscriptions.service";
@Component({
  selector: 'app-inscriptions-detail',
  templateUrl: './inscriptions-detail.component.html',
  styleUrl: './inscriptions-detail.component.scss'
})
export class InscriptionsDetailComponent implements OnInit , AfterViewInit {

  public OpenStatuses = InscriptionsConstants.OpenStatuses;
  @ViewChild("sectionSubscribe", { static: true }) sectionSubscribeDiv: ElementRef;
  private ref: DynamicDialogRef | undefined;
  inscriptionId: string;
  inscription?: InscriptionsDTO;
  community?: CommunityDTO;
  public freePlaces: number;
  public openStatus: string;
  public isFull: boolean = false;

  customFieldValues: CustomFieldValueDTO[];
  communityName: string = "";
  public defaultImg: string;
  public isLoading = true;
  public dateFrom: Date;
  public dateTo: Date;
  public isMobile: boolean;
  public dateOpen: DateTime;
  public dateClose: DateTime;
  private availabilitySubscription!: Subscription;
  @HostListener('window:resize') onresize() {
    this.isMobile = this.screenSizeService.isScreenSmall();
  }
  faArrowLeft = faArrowLeft;
  constructor(
    private actRoute: ActivatedRoute,
    private apiService: ApiService,
    private communityService: CommunityService,
    private titleService: Title,
    private dialogService: DialogService,
    private translate: TranslateService,
    private screenSizeService: ScreenSizeService,
    private metadataService: MetadataService,
    private srv: InscriptionsService,
    private _pipeIMG: ImageUrlPipe
  ) {
    this.inscriptionId = this.actRoute.snapshot.params['id'];
    this.communityName = this.actRoute.snapshot.params['community'];
  }
  ngOnInit(): void {

    this.isMobile = this.screenSizeService.isScreenSmall();
    this.defaultImg = this.communityService.defaultImg;
    this.init();
  }
  scrollToSubscribe(){
    this.sectionSubscribeDiv.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }
  ngAfterViewInit() {
    setTimeout(() => {


      this.scrollToSubscribe()
    }, 0);

  }

  getCommunity() {
    this.apiService.getCommunityByCode(this.communityName).subscribe(responseData => {
      this.community = responseData;
    })
  }
  private init() {

    this.getCommunity();
    this.getFieldsAdditionalInfo()
    this.apiService.getOneInscription(this.communityName, Number(this.inscriptionId))
      .subscribe(responseData => {
        if (responseData) {
          this.inscription = responseData;

          if(this.inscription.images && this.inscription.images.length > 0) {
            this._pipeIMG.transform(this.inscription?.images[0].path).subscribe(x=>{
              let absoluteImageUrl = x;
              const metadataObj = {
                title: Functions.decode(this.inscription?.name),
                description: Functions.decode(this.inscription.name),
                image: absoluteImageUrl ? Functions.decode(absoluteImageUrl) : null
              };

              this.metadataService.updateMetadata(metadataObj);
            });

          }

          this.titleService.setTitle(this.inscription?.name);

          this.dateOpen = DateTime.fromISO(this.inscription.dateOpen);
          this.dateClose = DateTime.fromISO(this.inscription.dateClose);

          if (this.dateOpen <= DateTime.now() && this.dateClose > DateTime.now()){
            this.openStatus = InscriptionsConstants.OpenStatuses.OPEN;
          } else if (this.dateOpen > DateTime.now()){
            this.openStatus = InscriptionsConstants.OpenStatuses.FUTURE;
          } else if (this.dateClose < DateTime.now()){
            this.openStatus = InscriptionsConstants.OpenStatuses.PAST;
          }

          if (this.openStatus == InscriptionsConstants.OpenStatuses.OPEN){
            this.availabilitySubscription = this.srv.subscribeToAvailability(parseInt(this.inscriptionId))
                .subscribe((res : any) =>{
                  this.freePlaces = res.freePlaces;
                  this.isFull = (this.freePlaces != null && this.freePlaces <= 0);
                });
          }
          this.isLoading = false;
        }
      });
  }
  public getFieldsAdditionalInfo(){
    this.apiService.getCustomFieldsValues(this.communityName, Number(this.inscriptionId))
      .subscribe(responseData => {
        this.customFieldValues = responseData.filter(x=> x.customFieldPublicPermissions == "r");
      });
  }

  public openInscriptionForm() {
    this.ref = this.dialogService.open(InscriptionFormComponent,
      {
        closable: true,
        position: 'top',
        data: { inscription : this.inscription ,
        route:this.actRoute},
        styleClass: "top-position-class form-modal",
        width: '1000px',
        header: this.translate.instant('INSCRIPTIONS.FormTitle'),

      }
      )
  }

  public joinToWaitingList(){
    Swal.fire({
      title: this.translate.instant('INSCRIPTIONS.WaitingList.Title'),
      icon: 'info',
      input: 'text',
      text: this.translate.instant('INSCRIPTIONS.WaitingList.Subtitle'),
      inputPlaceholder: this.translate.instant('PLACEHOLDERS.Email'),
      showCancelButton: true,
      //cancelButtonColor: '#2f4050',
      confirmButtonText: this.translate.instant('Send'),
      confirmButtonColor: this.community.color1,
      cancelButtonText: this.translate.instant('Cancel'),
      showLoaderOnConfirm: true,
      reverseButtons: true,
      didOpen: function () {
        if (Swal.getInput().value === '') {
          Swal.getConfirmButton().setAttribute('disabled' , 'disabled');
        }
        Swal.getInput().addEventListener('keyup', function(e ) {
          if ((<HTMLInputElement>e.target).value === '') {
            Swal.getConfirmButton().setAttribute('disabled' , 'disabled');
          } else {
            Swal.getConfirmButton().removeAttribute('disabled');
          }
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: (result) => {
        return this.apiService.sendMailTowaitingList(this.communityName, this.inscription.id, result)
          .toPromise()
          .catch(error => {
            console.error(error);
            Swal.showValidationMessage(
              'Error'
            );
          });
      }
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: this.translate.instant('INSCRIPTIONS.WaitingList.SuccessTitle'),
          icon: 'success',
          text: this.translate.instant('INSCRIPTIONS.WaitingList.SuccessSubtitle'),
          confirmButtonText: this.translate.instant('Accept'),
        });
      }
    });
  }

}
