import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import {CommunityDTO} from "../../../DTO/CommunityDTO";
import {ApiService} from "../../../services/api.service";
import {ActivatedRoute, Router } from "@angular/router";
import {Title} from "@angular/platform-browser";
import {MetadataService} from "../../../services/metadata.service";
import {ImageUrlPipe} from "../../../pipes/image-url.pipe";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {EsdevenimentDTO} from "../../../DTO/EsdevenimentDTO";
import { Functions } from 'src/app/helpers/Functions';
import { CommunityService } from 'src/app/services/community/community.service';
import { TicketDTO } from 'src/app/DTO/TicketDTO';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DateTime } from 'luxon';
import { PaymentModalComponent } from './payment-modal/payment-modal.component';
import { TicketsService } from './payment-modal/services/tickets.service';
import { atcb_action } from "add-to-calendar-button";
import { TranslateService } from '@ngx-translate/core';
import { DateIsBetweenResult, Helpers } from 'src/app/helpers';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { LocalizedDatePipe } from 'src/app/pipes/localized-date.pipe';

@Component({
  selector: 'app-events-detail',
  templateUrl: './events-detail.component.html',
  styleUrls: ['./events-detail.component.scss']
})
export class EventsDetailComponent {

  @ViewChild('addCalendar') addCalendar: ElementRef;
  @ViewChild('addCalendarBig') addCalendarBig: ElementRef;

  eventId: string = "";
  event?: EsdevenimentDTO;
  community?: CommunityDTO;
  communityName: string = "";

  public month: string;
  public day: string;

  public ticket: TicketDTO;

  public dateFrom: Date;
  public dateTo: Date;
  public sameDay: boolean;

  public isLoading = true;

  public addToCalendarData = {};
  public canAddCalendar = true;
  public addToCalendarLoaded = false;

  public availableTickets: number | null;

  public defaultImg: string;
  public isMobile: boolean;
  public hideBack = false;
  public isCustom: boolean;

  public buyButtonState: { enabled: boolean; text: string };

  private ref: DynamicDialogRef | undefined;
  private customCommunities = [
    'tortola-de-henares'
  ]

  faArrowLeft = faArrowLeft;

  @HostListener('window:resize') onresize() {
    this.isMobile = this.screenSizeService.isScreenSmall();
  }


  constructor(
    private apiService: ApiService,
    private actRoute: ActivatedRoute,
    private titleService: Title,
    private metadataService: MetadataService,
    private communityService: CommunityService,
    private ticketsService: TicketsService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private screenSizeService: ScreenSizeService,
    private router: Router,
    private _pipeIMG: ImageUrlPipe
  ) {

    this.eventId = this.actRoute.snapshot.params['id'];
    this.communityName = this.actRoute.snapshot.params['community'];

    this.isCustom = this.customCommunities.includes(this.communityName);

    if (this.actRoute.snapshot.queryParams['hideBack'] != undefined){
      this.hideBack = true;
    }

    this.buyButtonState = {
      enabled: false,
      text: this.translate.instant('TICKETS.ReserveTicket')
    }
  }

  ngOnInit(): void {
    this.isMobile = this.screenSizeService.isScreenSmall();
    this.defaultImg = this.communityService.defaultImg;
    this.init();
  }

  getCommunity() {
    this.apiService.getCommunityByCode(this.communityName).subscribe(responseData => {
      this.community = responseData;
    })
  }

  ngOnDestroy(): void {
  }

  public openTicketModal() {
    var width = this.isMobile ? '90%' : '700px'
    this.ref = this.dialogService.open(PaymentModalComponent,
      {
        closable: true,
        position: 'center',
        styleClass: 'header-transparent',
        baseZIndex: 1005,

        data: { ticket : this.ticket },
        width: width,
      })
  }

  public openMaps() {
    window.open('https://www.google.com/maps?q=' + this.event.locationLat + ',' + this.event.locationLng);
  }

  public addToCalendar(bigBtn?: boolean) {
    atcb_action(this.addToCalendarData, bigBtn ? this.addCalendarBig.nativeElement : this.addCalendar.nativeElement)
  }

  public downloadTicket(uuid: string) {
    this.router.navigateByUrl(`/${this.community.code}/tickets/print/${uuid}`);
  }

  private init() {
    this.getCommunity();

    this.apiService.getEsdevenimentBySlug(this.communityName, this.eventId)
      .subscribe(responseData => {
        if (responseData) {
          this.event = responseData;
          this.dateFrom = new Date(responseData.dateFrom);

          if(responseData.dateTo != null) {
            this.dateTo = new Date(responseData.dateTo);

            this.sameDay = this.dateFrom.toLocaleDateString() == this.dateTo.toLocaleDateString();
          } else {
            this.sameDay = false;
          }

          this.setCalendarButton();

          if (this.event.ticketId) {
            this.getTicket();
          }

          if (responseData.image != undefined && responseData.name != undefined) {

            //this.new.image = this.imageUrl(responseData?.image);
            this.titleService.setTitle(this.event?.name);

            var summary = "";

            if (this.event.summary) {
              if (this.event.summary.length > 160) {
                summary = this.event.summary.substring(0, 160) + "...";
              } else {
                summary = this.event.summary;
              }
            }
            this._pipeIMG.transform(responseData?.image).subscribe(x=>{
              let absoluteImageUrl = x;
              this.metadataService.updateMetadata({
                title: Functions.decode(this.event?.name),
                description: Functions.decode(summary),
                image: absoluteImageUrl ? Functions.decode(absoluteImageUrl) : null
              });
            });



          }
          this.isLoading = false;
        }

      });
  }

  private getTicket() {
    this.communityService.Get()
      .subscribe((community) => {
        const communityId = community.id;

        this.ticketsService.getTicketById(communityId, this.event.ticketId)
          .subscribe((result) => {
            this.ticket = result;
            this.refreshButtonState();
          });

          this.refreshAvailability(communityId);
      })
  }

  private setCalendarButton() {
    const name = this.event.name;
    const dateFrom = DateTime.fromJSDate(new Date(this.event.dateFrom));
    const startDate = dateFrom.toISODate();
    let endDate, startTime, endTime, locationString, dateTo;
    const now = DateTime.now();
    if (this.event.dateTo != null) {
      dateTo = DateTime.fromJSDate(new Date(this.event.dateTo));
      endDate = dateTo.toISODate();
      this.canAddCalendar = dateTo > now;
    } else {
      endDate = startDate;
      this.canAddCalendar = dateFrom > now;
    }

    if (!this.event.allDay) {
      startTime = dateFrom.toFormat('hh:mm');
      if(this.dateTo) {
        endTime = dateTo.toFormat('hh:mm');
      } else {
        startTime = null;
        endTime = null;
      }
    } else {
      startTime = null;
      endTime = null;
    }

    if (this.event.locationString && this.event.locationString != null) {
      locationString = this.event.locationString;
    }

    this.addToCalendarData = {
      name: name,
      startDate: startDate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime,
      location: locationString,
      timeZone: 'Europe/Madrid',
      options: ['Google', 'Apple', 'Outlook.com', 'ICal'],
      inline: true,
      buttonStyle: 'round',
      customLabels: { 'close': this.translate.instant('Close') }
    }

    this.addToCalendarLoaded = true;
  }

  private refreshAvailability(communityId: number) {
    this.ticketsService.getAvailabilityV2(communityId, this.event.ticketId)
      .subscribe(({ freePlaces }) => {
      this.availableTickets = freePlaces;
      this.refreshButtonState();
    });
  }

  private refreshButtonState() {
    let availableTickets = this.availableTickets == null || this.availableTickets > 0;

    if (!this.ticket){
      if (availableTickets){
        this.buyButtonState.text = this.translate.instant('TICKETS.ReserveTicket');
      } else {
        this.buyButtonState.text = this.translate.instant('Modules.Ticketing.Exceeded');
      }
      return;
    }

    let saleStartDate = new Date(this.ticket.saleDateFrom);

    let chkDates = Helpers.CheckIfDateIsBetween(this.ticket.saleDateFrom, this.ticket.saleDateTo);
    let inTime = (chkDates == DateIsBetweenResult.Between);

    let fullFree = this.ticket.prices.every(x => x.totalWithFee == 0);

    this.buyButtonState.enabled = inTime && availableTickets;

    if (inTime){
     if (availableTickets){
      this.buyButtonState.text = fullFree ? this.translate.instant('TICKETS.ReserveTicket') : this.translate.instant('TICKETS.BuyTickets');
     } else {
      this.buyButtonState.text = this.translate.instant('Modules.Ticketing.Exceeded');
     }
    } else if (chkDates == DateIsBetweenResult.Before){
      this.buyButtonState.text = this.translate.instant('TICKETS.SaleOpen') + ' ' + new LocalizedDatePipe(this.translate).transform(saleStartDate, 'EEEE dd MMMM HH:mm');
    } else {
      this.buyButtonState.text = this.translate.instant('TICKETS.SaleClosed');
    }

  }
}
