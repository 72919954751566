import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { CustomFieldDTO } from 'src/app/DTO/CustomFieldDTO';
import { UploadedFileModel } from 'src/app/components/file-uploader/file-uploader.component';
import { AppModule } from 'src/app/app.module';
import { FileUploaderComponentModule } from 'src/app/components/file-uploader/file-uploader.module';

@Component({
  selector: 'app-custom-field-file',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFieldFileComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomFieldFileComponent),
      multi: true
    }
  ],
  templateUrl: './file.component.html',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploaderComponentModule
  ],
  styleUrls: ['../../styles/fields.scss']
})
export class CustomFieldFileComponent implements OnInit, ControlValueAccessor{

  @Input()
  public customField: CustomFieldDTO;
  @Input()
  public readonly: boolean;

  public internalValue: any;
  public files: UploadedFileModel[] = [];

  onChange: (_: string) => void = (_: string) => { };

  constructor() { }

  ngOnInit(): void { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(): void { }

  writeValue(file: string): void {
    if(file) {
      this.files = this.modelFromString(file);
    }
  }

  validate() {
    return false;
  }

  public onFileChange(event: any) {
    if(event && event.path) {
      this.onChange(this.modelToString(event))
    }
  }

  private modelToString(file: UploadedFileModel): string {
    return `${file.path}|${file.originalName}`;
  }

  private modelFromString(file: string): UploadedFileModel[] {
    const values = file.split('|');
    const files: UploadedFileModel[] = [];

    const fileModel = {
      path: values[0],
      originalName: values[1]
    } as UploadedFileModel;

    files.push(fileModel);

    return files;
  }
}
