import { TicketPriceDTO } from "../TicketPriceDTO";

export class TicketMinDTO {
  id: number;
  isActive: boolean;
  title: string;
  summary: string;
  saleDateFrom: string;
  saleDateTo: string;
  images: any[] = [];
  prices: TicketPriceDTO[] = [];
  eventId?: number;
  eventName: string;
  eventDateFrom?: Date;
  eventDateTo?: Date;
  eventImage: string;
  categoryId?: number;
  categoryName: string;
  categoryColor: string;
  isOpened: boolean;
}
