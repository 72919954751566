import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from "primeng/dropdown";
import { CommonModule } from "@angular/common";
import { TranslateModule } from '@ngx-translate/core';
import { CustomFieldDTO } from 'src/app/DTO/CustomFieldDTO';
import { CustomFieldsConstants } from 'src/app/constants/CustomFieldsConstants';

@Component({
  selector: 'app-custom-field-dropdown',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFieldDropdownComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomFieldDropdownComponent),
      multi: true
    }
  ],
  templateUrl: './dropdown.component.html',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    TranslateModule,
],
  styleUrls: ['../../styles/fields.scss']
})
export class CustomFieldDropdownComponent implements OnInit, ControlValueAccessor{

  @Input()
  public customField: CustomFieldDTO | null;
  @Input()
  public readonly: boolean;

  public isTable = false;
  public internalValue: any;
  public show = false;
  public DropdownTypes = CustomFieldsConstants.DropdownTypes;

  onChange: (_: string) => void = (_: string) => { };

  constructor() { }

  ngOnInit(): void {
    if(this.customField) {
      this.isTable = this.customField.dropdownType === 'TABLE'
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(): void { }

  writeValue(val: string): void {
    if (this.customField) {
      this.customField.value = val;

      if (!this.isTable) {
        this.customField.dropdownOptionsArrayShow = this.customField.dropdownOptions.split('|')
      }
    }

    this.internalValue = this.modelFromString(val);
  }

  validate() {
    return false;
  }

  updateChanges(value: string) {
    this.onChange(value);
  }

  private modelFromString(value: string): any {
    if (!value) { return null; }
    return value;
  }
}
