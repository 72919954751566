import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { UserDTO } from 'src/app/DTO/UserDTO';
import {CustomFieldDTO} from "../../../../../DTO/CustomFieldDTO";
import {DateTime} from "luxon";
import {CommunityService} from "../../../../../services/community/community.service";
import {CommunityDTO} from "../../../../../DTO/CommunityDTO";
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-gg';
import { first, map } from 'rxjs';
import parsePhoneNumberFromString from 'libphonenumber-js';

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss'],
})
export class UserDataComponent implements OnInit {

  @Input() fullFree: boolean;
  @Input() customFields: CustomFieldDTO[];

  @Input() submitAttempt = new EventEmitter();
  @Output() userEmitter = new EventEmitter();
  @Output() formValid = new EventEmitter<boolean>();

  public form: FormGroup;
  public submitAttempted = false;
  public community: CommunityDTO = new CommunityDTO();
  public formattedPhone: any;
  public SearchCountryField = SearchCountryField;
  public CountryISO = CountryISO;
  public PhoneNumberFormat = PhoneNumberFormat;
  public preferredCountries: CountryISO[] = [CountryISO.Spain];
  public defaultCountry: any;
  public phone: string
  public showPhone = false;

  private selectedCountry: string;

  constructor(private _formBuilder: FormBuilder,
              private communityService: CommunityService,) {
  }

  ngOnInit(): void {
    this.setPhoneDefaultCountry()
    this.setForm();
    this.submitAttempt.subscribe((x => this.submitAttempted = true));
    this.communityService.Get().subscribe(res=> {
      this.community = res;
    });
  }

  private setPhoneDefaultCountry() {
    this.communityService.GetParameters()
      .pipe(
        first(),
        map(x => {
          if (x['PHONE_DEFAULT_COUNTRY_CODE']) {
            const defaultCode = x['PHONE_DEFAULT_COUNTRY_CODE'];
            const enumValues = Object.values(CountryISO);

            if (enumValues.includes(defaultCode)) {
              this.defaultCountry = defaultCode as CountryISO[keyof CountryISO]
            }

            if (!this.preferredCountries.includes(defaultCode)) {
              this.preferredCountries.push(defaultCode);
            }
          } else {
            this.defaultCountry = this.preferredCountries[0];
          }

          this.showPhone = true;
        })
      ).subscribe()
  }

  private setFormattedPhone(phone: string) {
    const parsedPhone = parsePhoneNumberFromString(phone, this.selectedCountry || this.defaultCountry);
    this.formattedPhone = parsedPhone.formatNational();
  }

  private setForm() {
    const mailReg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      surname: new FormControl('', Validators.required),
      mail: new FormControl('', [Validators.required, Validators.pattern(mailReg)]),
      phone: new FormControl('', Validators.required),
      acceptance: new FormControl('', null),
      user: new FormControl('')
    })

    for (const customField of this.customFields) {
      this.form.addControl(customField.id.toString(), this._formBuilder.control(null));
    }


    this.form.valueChanges.subscribe((x)=> {
      var user = new UserDTO();
      user.name = this.form.get('name').value;
      user.mail = this.form.get('mail').value;
      user.surname = this.form.get('surname').value;
      user.phone = this.form.get('phone').value;
      var acceptance =  this.form.get('acceptance').value;

      if(acceptance)
      {
        user.acceptanceCommercial = DateTime.now().toJSDate();
      }else{
        user.acceptanceCommercial = null;
      }


      this.userEmitter.emit({
        'user-data': user
      })
      this.formValid.emit(this.form.valid);
    })
  }
}
