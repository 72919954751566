<ng-container *ngIf="!readonly">
  <input 
    type="text"
    pInputText
    [ngModel]="internalValue"
    (ngModelChange)="updateChanges($event)"
    styleClass="w-100"/>
</ng-container>

<ng-container *ngIf="readonly">
  <span>{{ internalValue }}</span>
</ng-container>