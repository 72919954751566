<ng-container *ngIf="!readonly">
  <ng-container *ngIf="!isTable">
    <p-dropdown *ngIf="customField.dropdownOptionsArrayShow"
      [placeholder]="'SelectOptionPlaceholder' | translate"
      [options]="customField.dropdownOptionsArrayShow"
      [(ngModel)]="internalValue"
      [filter]="true"
      (ngModelChange)="updateChanges($event)"
      [showClear]="!customField.required"
      appendTo="body"
      [overlayOptions]="{
        baseZIndex: 1055
      }"
      styleClass="w-100"
    ></p-dropdown>
  </ng-container>

  <ng-container *ngIf="isTable">
    <p-dropdown [options]="customField.dropdownOptionsTable"
      [ngModelOptions]="{standalone: true}"
      [(ngModel)]="customField.value"
      optionValue="name"
      optionLabel="name"
      [filter]="true"
      [showClear]="true"
      styleClass="w-100"
      [placeholder]="'SelectOptionPlaceholder' | translate"
      (ngModelChange)="updateChanges($event);">
      <ng-template let-selected pTemplate="selectedItem">
        <div class="flex align-items-center gap-2">
          <div>
            {{ selected.name }}
            <b *ngIf="selected.price">
              +{{ selected.price | currency: 'EUR':'symbol':'1.0-2'}}
            </b>
          </div>
        </div>
      </ng-template>
      <ng-template let-x pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>
            {{ x.name }}
            <b *ngIf="x.price">
              +{{ x.price | currency: 'EUR':'symbol':'1.0-2'}}
            </b>
          </div>
        </div>
      </ng-template>
    </p-dropdown>
  </ng-container>
</ng-container>

<ng-container *ngIf="readonly">
  <span>{{ internalValue }}</span>
</ng-container>
