import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { faBars, faBell } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { first, firstValueFrom } from 'rxjs';
import { CommunityDTO } from 'src/app/DTO/CommunityDTO';
import { Functions } from 'src/app/helpers/Functions';
import { ApiService } from 'src/app/services/api.service';
import { MetadataService } from 'src/app/services/metadata.service';
import { REQ_URL } from 'src/app/services/tokens/req-url';

@Component({
  selector: 'app-share-link',
  templateUrl: './share-link.component.html',
  styleUrls: ['./share-link.component.scss']
})
export class ShareLinkComponent implements OnInit  {

  private static CustomAppCommunities = [
    'begur',
    'gironahostaleria',
    'asetrans',
    'bcn-digital-congress',
    'solsona'
  ];

  public isLoading = true;
  
  public og_title = '';
  public og_description = '';
  public og_image = '';
  public og_url = '';
  
  public appName = 'Wecoo';
  public LANG_PREFIX = 'WECOO';

  public urlAndroid: string;
  public urlIos: string;

  public communityCode: string;

  public isWeTown = false;
  public hasContent = false;
  public community: any;

  public faBell = faBell;
  public faBars = faBars;

  public gradientFrom: string;
  public gradientTo: string;

  public assetsDir: string;

  public appIcon: string | null;
  public appScreenshot: string | null;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private translate: TranslateService,
    private titleService: Title,
    private metadataService: MetadataService,
    @Optional() @Inject(REQ_URL) private reqUrl: string,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }
  
  async ngOnInit(): Promise<void> {
    if(!this.reqUrl){
      this.reqUrl = window.location.href;
    }
    
    var url = new URL(this.reqUrl);
    console.log(url);

    this.isWeTown = url.hostname.includes('wetown') || this.reqUrl.includes("wetown");
    this.appName = (this.isWeTown)? 'WeTown' : 'Wecoo';

    // Try to match /c/<communityCode> first
    let communityCodeFound = url.pathname.match(/^\/c\/([\w-]+)/);

    // If no match for /c/, then try /<communityCode>
    if (!communityCodeFound) {
      communityCodeFound = url.pathname.match(/^\/([\w-]+)/);
    }

    // If a community code was found in the path, assign it
    if (communityCodeFound && communityCodeFound[1]) {
      this.communityCode = communityCodeFound[1];
    }

    // Finally, if there is a "c" query parameter, it takes precedence
    let params = await firstValueFrom(this.route.queryParams);
    if (params['c'] !== undefined) {
      this.communityCode = params['c'];
    }

    this.refreshTexts();

    let appIconFallback = () => {
      if (this.isWeTown){
        return this.setAppIconByCommunity('wetown');
      } else {
        return this.setAppIconByCommunity('wecoo');
      }
    }
    
    if(this.communityCode){
      let community = await firstValueFrom(this.api.getCommunityByCode(this.communityCode));
      if (community){

        if (community.appName){
          this.appName = community.appName;
        }

        this.isWeTown = (community.communityType == 0);
        this.gradientFrom = community.color1;
        this.gradientTo = community.color2;
        this.refreshTexts();
        
        await this.setAppIconByCommunity(community.code);
        
      } else {
        await appIconFallback();
      }

      this.refreshStoresUrls(params, community);
      await this.refreshOpenGraphData();

      this.isLoading = false;

    } else {
      this.refreshStoresUrls(params, null);
      await this.refreshOpenGraphData();

      await appIconFallback();

      this.isLoading = false;
    }
  }

  public redirectToAndroid(ev: any = null) {
    ev?.preventDefault();
    window.location.href = this.urlAndroid; 
  }

  public redirectToIOS(ev: any = null) {
    ev?.preventDefault();
    window.location.href = this.urlIos; 
  }

  private async setAppIconByCommunity(communityCode: string){
    try {
      let res = await firstValueFrom(this.api.appleAppData(communityCode));
      if (res && res.resultCount > 0) {
        let app = res.results[0];
        this.appIcon = app.artworkUrl512;
      }
    } catch (error) {}
  }

  private async setAppIconByAppId(customAppIdApple: string){
    if (customAppIdApple){
      let appId = customAppIdApple;
      if (appId.startsWith('id')){
        appId = appId.substring(2)
      }
      
      try {
        let res = await firstValueFrom(this.api.itunesLookup(appId));
        if (res.resultCount > 0) {
          let app = res.results[0];
          this.appIcon = app.artworkUrl512;
        }
      } catch (error) {}

    } else {
      if (this.isWeTown){
        return this.setAppIconByAppId('id1560741218');
      } else {
        return this.setAppIconByAppId('id6450015152');
      }
    }
  }

  private refreshStoresUrls(params: Params, community?: CommunityDTO){
    this.urlAndroid = this.getUrlsAndroid(community?.customAppId);
    this.urlIos = this.getUrlsIos(community?.customAppIdApple);
    if(params['download'] != undefined || this.reqUrl.startsWith('/download')){
      this.redirectToStore();
    }
  }

  private async refreshOpenGraphData(){
    let res = await firstValueFrom(this.api.getShareInfo(this.reqUrl))

    if (res){
      
      if (isPlatformBrowser(this.platformId) && res.webAppUrl) {
        window.location.href = res.webAppUrl;
        await new Promise(x => setTimeout(x, 3000));
      }

      this.hasContent = true;
      if (res?.title){
        this.og_title = res?.title + " - " + this.appName;
      }
      if (res?.description){
        this.og_description = res?.description
      }
      if (res?.image){
        this.og_image = res?.image
      }
      if (res?.url){
        this.og_url = res?.url
      }
    }

    this.titleService.setTitle(this.og_title);
    this.metadataService.updateMetadata({
      title: Functions.decode(this.og_title),
      description: Functions.decode(this.og_description),
      image: Functions.decode(this.og_image),
    });
  }

  private refreshTexts(){
    this.og_title = this.appName;

    if (this.isWeTown){
      this.LANG_PREFIX = "WETOWN";
      this.og_description = `Descarrega\'t ${this.appName}, per informar-te, participar i cooperar amb el teu municipi.`
      
      if (!this.gradientFrom){
        this.gradientFrom = '#7900dc';
        this.gradientTo = '#ff3403';
      }
      this.assetsDir = 'wetown';

    } else {
      this.LANG_PREFIX = "WECOO";
      this.og_description = `Descarrega\'t ${this.appName}, per informar-te, participar i cooperar amb la teva comunitat.`

      if(!this.gradientFrom){
        this.gradientFrom = '#45a7cc';
        this.gradientTo = '#b47dde';
      }
      this.assetsDir = 'wecoo';
    }

    if(ShareLinkComponent.CustomAppCommunities.includes(this.communityCode)){
      this.assetsDir = this.communityCode;
    }
  }

  private redirectToStore() {
    var userAgent = navigator.userAgent || navigator.vendor;
      if (/android/i.test(userAgent)) {
          this.redirectToAndroid();
      } else if (this.isIOS()) { // iOS detection from: http://stackoverflow.com/a/9039885/177710
        this.redirectToIOS();
      }
  }

  private isIOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  private getUrlsAndroid(appId?: string): string{
    if(appId){
      return `https://play.google.com/store/apps/details?id=${appId}`;
    } else if(this.isWeTown){
      return this.getUrlsAndroid('app.wetown');
    } else {
      return this.getUrlsAndroid('app.wecoo');
    }

  }

  private getUrlsIos(appId?: string): string{
    if(appId){
      return `https://apps.apple.com/es/app/${appId}`;
    } else if(this.isWeTown){
      return this.getUrlsIos('id1560741218');
    } else {
      return this.getUrlsIos('id6450015152');
    }
  }

}
