<div class="loading-screen" *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>

<div *ngIf="!isLoading" class="flex-container" 
    [style]="cssVariables"    
    [style.background-image]="(!isCustomCommunity ? 'url(' + bgImage + ')' : '')">
    <div class="image-div">
        <img *ngIf="!isCustomCommunity" [src]="logoImage">
        <img *ngIf="isCustomCommunity" [src]="community.logoNegative ?? community.image | imageUrl | async">
        <div class="by-wetown" *ngIf="!isWeCoo && isCustomCommunity">
            <span>by</span> <img src="assets/logos/wetown/horizontal-white.svg" />
        </div>
        <div class="by-weco" *ngIf="isWeCoo && isCustomCommunity">
            <span>by</span>
            <img src="assets/logos/wecoo/horizontal-white.svg" />
        </div>
        <span>{{ 'PASSWORD_RESET.RestartPassword' | translate }}</span>
    </div>

    <form [formGroup]="form">
        <div class="pass-div">
            <label for="user">{{ 'PASSWORD_RESET.NewPassword' | translate }}</label>
            <input 
                id="password"
                name="password"
                type="password"
                pInputText
                formControlName="password"
                [class.error-input]="submitAttempt && (form.get('password').hasError('required') || form.get('confirmPassword').hasError('minLength'))"                
            >
            <div class="error-message" *ngIf="submitAttempt && form.get('password').hasError('required')">
                <span style="color: red">{{ 'RequiredField' | translate }}</span>
            </div>
            <div class="error-message" *ngIf="(submitAttempt && form.get('password').hasError('minlength')) || (form.dirty && form.get('password').hasError('minlength'))">
                <span style="color: red">{{ 'PASSWORD_RESET.Min6Caracters' | translate }}</span>
            </div>
        </div>
        
        <div class="pass-div">
            <label for="user">{{ 'PASSWORD_RESET.RepeatPassword' | translate }}</label>
            <input 
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                pInputText
                formControlName="confirmPassword"
                [class.error-input]="submitAttempt && (form.get('confirmPassword').hasError('required') || form.get('confirmPassword').hasError('minLength'))"                            
            >
            <div class="error-message" *ngIf="submitAttempt && form.get('confirmPassword').hasError('required')">
                <span style="color: red">{{ 'RequiredField' | translate }}</span>
            </div>
            <div class="error-message" *ngIf="submitAttempt && form.get('confirmPassword').hasError('minLength')">
                <span style="color: red">{{ 'PASSWORD_RESET.Min6Caracters' | translate }}</span>
            </div>
        </div>   
    </form>

    <div class="button-div">
        <button 
            [style.background-color]="!isCustomCommunity ? buttonBg : null"
            [style.color]="!isCustomCommunity ? buttonColor : null"
            type="button"
            (click)="sendResetPassword()"
            [disabled]="!form.valid"
            [style.opacity]="!form.valid ? '0.3' : '1'"
        >
        {{ 'Send' | translate }}</button>
    </div>
</div>
