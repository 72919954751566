import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from "primeng/inputtext";
import { CommonModule } from "@angular/common";

@Component({
  selector: 'app-custom-field-text',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFieldTextComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomFieldTextComponent),
      multi: true
    }
  ],
  templateUrl: './text.component.html',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
  ],
  styleUrls: ['../../styles/fields.scss']
})
export class CustomFieldTextComponent implements OnInit, ControlValueAccessor {

  @Input()
  public readonly: boolean;

  public internalValue: string;

  onChange: (_: string) => void = (_: string) => { };

  constructor() { }

  ngOnInit(): void { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(): void { }

  writeValue(val: string): void {
    this.internalValue = val;

    if(val) {
      this.updateChanges(val)
    }
  }

  validate() {
    return false;
  }

  updateChanges(value: string) {
    this.onChange(value);
  }
}
