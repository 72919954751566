import { computed, Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { EMPTY, merge, Subject, timer } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { CommunityService } from 'src/app/services/community/community.service';
import { API_URL } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InscriptionsService {

  private communityCode : string;

  private availabilityInscriptionId = signal<number | null>(null);
  private availabilityInscriptionIdInterval = signal<number>(10000); // por defecto 10s

  // Subject that will emit whenever a new subscriber arrives
  private newSubscriber$ = new Subject<void>();

  // Computed signal that reacts to changes in id or interval
  // We convert it to an Observable using toObservable()
  private availabilityParams$ = toObservable(
    computed(() => [this.availabilityInscriptionId(), this.availabilityInscriptionIdInterval()])
  );
  
  // Main availability polling observable
  private availabilityPolling$ = this.availabilityParams$.pipe(
    switchMap(([id, interval]) => {
      // If there is no valid ID, we skip polling
      if (id === null) {
        return EMPTY;
      }
      // Combine an immediate trigger (newSubscriber$) with a timer
      // that starts at 0 and repeats every 'interval' milliseconds
      return merge(
        this.newSubscriber$,
        timer(0, interval)
      ).pipe(
        // Replace the URL with your actual endpoint
        switchMap(() => this.getAvailability(id))
      );
    }),
    // shareReplay with refCount = true ensures:
    // - The polling starts when the first subscriber arrives.
    // - It stops when the last subscriber unsubscribes.
    shareReplay({ bufferSize: 1, refCount: true })
  );
  
  constructor(
    private communityService: CommunityService,
    private api: ApiService
  ) {
    this.communityService.Get().subscribe((res) => {
        this.communityCode = res.code;
    });
   }

  /**
   * Subscribe to the availability polling stream.
   * 
   * @param id Numeric ID that will be appended to the URL.
   * @param interval Interval in milliseconds between calls (default: 10000).
   * @returns An observable that will emit the HTTP response data.
   */
  public subscribeToAvailability(id: number, interval = 10000) {
    // Update the signals, causing the main stream to rebuild if needed
    this.availabilityInscriptionId.set(id);
    this.availabilityInscriptionIdInterval.set(interval);

    // Trigger a new emission for any new subscriber
    this.newSubscriber$.next();

    // Return the polling observable
    return this.availabilityPolling$;
  }

    private getAvailability(inscriptionId: number) {
        return this.api.get(`${API_URL}/v2/Communities/${this.communityCode}/Modules/Inscriptions/${inscriptionId}/availability`);
    }

}
