import { HttpClient,  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {API_URL} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(
    private http: HttpClient
    ) { }


  public getURL(path: string): Observable<string> {
    return this.http.get<string>(`${API_URL}/Files/GetURl?path=${path}` ,
        {responseType: 'text' as 'json'});
  }

}
