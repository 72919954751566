import { Component, ElementRef, HostListener, OnInit, signal, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Functions } from 'src/app/helpers/Functions';
import { CommunityService } from 'src/app/services/community/community.service';
import { TicketDTO } from 'src/app/DTO/TicketDTO';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DateTime } from 'luxon';
import { atcb_action } from "add-to-calendar-button";
import { TranslateService } from '@ngx-translate/core';
import { DateIsBetweenResult, Helpers } from 'src/app/helpers';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { LocalizedDatePipe } from 'src/app/pipes/localized-date.pipe';
import { EsdevenimentDTO } from 'src/app/DTO/EsdevenimentDTO';
import { CommunityDTO } from 'src/app/DTO/CommunityDTO';
import { ApiService } from 'src/app/services/api.service';
import { MetadataService } from 'src/app/services/metadata.service';
import { TicketsService } from 'src/app/pages/events/events-detail/payment-modal/services/tickets.service';
import { PaymentModalComponent } from 'src/app/pages/events/events-detail/payment-modal/payment-modal.component';
import { ImageUrlPipe } from 'src/app/pipes/image-url.pipe';
import { firstValueFrom } from 'rxjs';
import { DetailDTO } from 'src/app/DTO/DetailDTO';

@Component({
  selector: 'app-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.scss']
})
export class TicketDetailComponent implements OnInit {

  @ViewChild('addCalendar') addCalendar: ElementRef;
  @ViewChild('addCalendarBig') addCalendarBig: ElementRef;

  eventId: string = "";
  // event?: EsdevenimentDTO;
  community?: CommunityDTO;
  communityName: string = "";

  public month: string;
  public day: string;

  public model = signal(new DetailDTO);
  public ticket = signal(new TicketDTO);
  public event = signal(new EsdevenimentDTO);

  public dateFrom: Date;
  public dateTo: Date;
  public sameDay: boolean;

  public $loading = signal(true);

  public addToCalendarData = {};
  public canAddCalendar = true;
  public addToCalendarLoaded = false;

  public availableTickets: number | null;

  public defaultImg: string;
  public isMobile: boolean;
  public hideBack = false;
  public isCustom: boolean;

  public buyButtonState: { enabled: boolean; text: string };

  private ref: DynamicDialogRef | undefined;
  private customCommunities = [
    'tortola-de-henares'
  ]

  faArrowLeft = faArrowLeft;

  @HostListener('window:resize') onresize() {
    this.isMobile = this.screenSizeService.isScreenSmall();
  }


  constructor(
    private apiService: ApiService,
    private actRoute: ActivatedRoute,
    private titleService: Title,
    private metadataService: MetadataService,
    private communityService: CommunityService,
    private ticketsService: TicketsService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private screenSizeService: ScreenSizeService,
    private router: Router,
    private _pipeIMG: ImageUrlPipe,
  ) {

    this.eventId = this.actRoute.snapshot.params['id'];
    this.communityName = this.actRoute.snapshot.params['community'];

    this.isCustom = this.customCommunities.includes(this.communityName);

    if (this.actRoute.snapshot.queryParams['hideBack'] != undefined) {
      this.hideBack = true;
    }

    this.buyButtonState = {
      enabled: false,
      text: this.translate.instant('TICKETS.ReserveTicket')
    }
  }

  ngOnInit() {
    this.isMobile = this.screenSizeService.isScreenSmall();
    this.defaultImg = this.communityService.defaultImg;
    this.init();
  }

  private getCommunity() {
    return firstValueFrom(this.apiService.getCommunityByCode(this.communityName));
  }

  ngOnDestroy(): void {
  }

  public openTicketModal() {
    var width = this.isMobile ? '90%' : '700px'
    this.ref = this.dialogService.open(PaymentModalComponent,
      {
        closable: true,
        position: 'center',
        styleClass: 'header-transparent',
        baseZIndex: 1005,
        data: { ticket: this.ticket() },
        width: width,
      })
  }

  public openMaps() {
    window.open('https://www.google.com/maps?q=' + this.event().locationLat + ',' + this.event().locationLng);
  }

  public addToCalendar(bigBtn?: boolean) {
    atcb_action(this.addToCalendarData, bigBtn ? this.addCalendarBig.nativeElement : this.addCalendar.nativeElement)
  }

  public downloadTicket(uuid: string) {
    this.router.navigateByUrl(`/${this.community.code}/tickets/print/${uuid}`);
  }

  private async init() {
    this.community = await this.getCommunity();

    this.getTicket()
      .subscribe((ticket) => {
        this.setTicket(ticket)
      });
  }

  private getTicket() {
      const id = this.actRoute.snapshot.params['id'];
      return this.ticketsService
        .getTicketById(this.community.id, id);
  }

  private async setTicket(ticket: TicketDTO) {
    this.ticket.set(ticket);

    if(ticket.eventId) {
      const event = await this.getEvent();
      this.event.set(event);
    }

    const detail = this.setDetail();

    if(detail.dateTo !== null) {
      this.sameDay = DateTime.fromJSDate(detail.dateFrom).hasSame(DateTime.fromJSDate(detail.dateTo), 'day');
    }

    if(detail.image && detail.name) {
      this.titleService.setTitle(detail.name);

      this._pipeIMG.transform(detail.image).subscribe(x=>{
         const absoluteImageUrl = x;
        this.metadataService.updateMetadata({
          title: Functions.decode(detail.name),
          description: Functions.decode(detail.summary),
          image: absoluteImageUrl ? Functions.decode(absoluteImageUrl) : null
        });
      });

    }

    this.model.set(
      detail
    );

    this.setCalendarButton();
    this.refreshAvailability(this.community.id);
    this.refreshButtonState();

    this.$loading.set(false);
  }

  private getEvent() {
    return firstValueFrom(this.apiService.getEsdevenimentById(this.community.code, this.ticket().eventId));
  }

  private setDetail(): DetailDTO {
    if(this.ticket().eventId) {
      return {
        name: this.event().name,
        description: this.event().description,
        image: this.event().image,
        dateFrom: DateTime.fromISO(this.event().dateFrom.toString()).toJSDate(),
        dateTo: DateTime.fromISO(this.event().dateFrom.toString()).toJSDate(),
        locationString: this.event().locationString,
        locationLat: this.event().locationLat,
        locationLng: this.event().locationLng,
        files: this.event().files,
        id: this.event().id,
        ticketId: this.ticket().id,
        allDay: this.event().allDay,
        summary: this.event().summary,
        categoryName: this.event().categoryName
      } as DetailDTO;
    } else {
      return {
        name: this.ticket().title,
        description: this.ticket().body,
        image: this.ticket().images[0].path,
        dateFrom: DateTime.fromISO(this.ticket().saleDateFrom).toJSDate(),
        dateTo: DateTime.fromISO(this.ticket().saleDateTo).toJSDate(),
        files: this.ticket().files,
        id: this.ticket().id,
        summary: this.ticket().summary,
        allDay: false,
        categoryName: this.ticket().categoryName,
        ticketId: this.ticket().id,
      } as DetailDTO;
    }
  }

  private setCalendarButton() {
    const name = this.model().name;
    const dateFrom = DateTime.fromJSDate(new Date(this.model().dateFrom));
    const startDate = dateFrom.toISODate();
    let endDate, startTime, endTime, locationString, dateTo;
    const now = DateTime.now();
    if (this.model().dateTo != null) {
      dateTo = DateTime.fromJSDate(new Date(this.model().dateTo));
      endDate = dateTo.toISODate();
      this.canAddCalendar = dateTo > now;
    } else {
      endDate = startDate;
      this.canAddCalendar = dateFrom > now;
    }

    if (!this.model().allDay) {
      startTime = dateFrom.toFormat('hh:mm');
      if (this.dateTo) {
        endTime = dateTo.toFormat('hh:mm');
      } else {
        startTime = null;
        endTime = null;
      }
    } else {
      startTime = null;
      endTime = null;
    }

    if (this.model().locationString && this.model().locationString != null) {
      locationString = this.model().locationString;
    }

    this.addToCalendarData = {
      name: name,
      startDate: startDate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime,
      location: locationString,
      timeZone: 'Europe/Madrid',
      options: ['Google', 'Apple', 'Outlook.com', 'ICal'],
      inline: true,
      buttonStyle: 'round',
      customLabels: { 'close': this.translate.instant('Close') }
    }

    this.addToCalendarLoaded = true;
  }

  private refreshAvailability(communityId: number) {
    this.ticketsService.getAvailability(communityId, this.model().ticketId).subscribe((res) => {
      this.availableTickets = res;
      this.refreshButtonState();
    });
  }

  private refreshButtonState() {
    let availableTickets = this.availableTickets == null || this.availableTickets > 0;

    if (!this.ticket()) {
      if (availableTickets) {
        this.buyButtonState.text = this.translate.instant('TICKETS.ReserveTicket');
      } else {
        this.buyButtonState.text = this.translate.instant('Modules.Ticketing.Exceeded');
      }
      return;
    }

    let saleStartDate = new Date(this.ticket().saleDateFrom);

    let chkDates = Helpers.CheckIfDateIsBetween(this.ticket().saleDateFrom, this.ticket().saleDateTo);
    let inTime = (chkDates == DateIsBetweenResult.Between);

    let fullFree = this.ticket().prices.every(x => x.totalWithFee == 0);

    this.buyButtonState.enabled = inTime && availableTickets;

    if (inTime) {
      if (availableTickets) {
        this.buyButtonState.text = fullFree ? this.translate.instant('TICKETS.ReserveTicket') : this.translate.instant('TICKETS.BuyTickets');
      } else {
        this.buyButtonState.text = this.translate.instant('Modules.Ticketing.Exceeded');
      }
    } else if (chkDates == DateIsBetweenResult.Before) {
      this.buyButtonState.text = this.translate.instant('TICKETS.SaleOpen') + ' ' + new LocalizedDatePipe(this.translate).transform(saleStartDate, 'EEEE dd MMMM HH:mm');
    } else {
      this.buyButtonState.text = this.translate.instant('TICKETS.SaleClosed');
    }

  }
}
