<div class="header" *ngIf="community" [ngStyle]="{ 'background-color' : community?.color1 }">
    <div class="max-width-wrapper" >
        <div class="row">
            <div class="col">
                <a class="title" [href]="'/' + community?.code">
                    <ng-container *ngIf="community">
                        <img [src]="(community.logoHorizontalNegative ?? community.logoNegative) | imageUrl | async" />
                    </ng-container>
                </a>
            </div>
            <div class="col">
                <div *ngIf="langs.length > 1" class="lang-selector text-end">
                    <ng-container *ngFor="let l of langs; last as isLast">
                        <span [class.active]="l.key == currentLang" (click)="changeLang(l.key)" [title]="l.name">{{ l.key | uppercase }}</span> <span *ngIf="!isLast">|</span>
                    </ng-container>
                </div>
                  <div class="col-menu">
                    <div class="menu desktop" *ngFor="let menuTab of menuTabs;  index as i" data-index="#i">

                        <div class="menuElement text-m" style="width: max-content" routerLinkActive="selectedItem" [routerLink]="['/' + this.community?.code + '/' + menuTab.route]">
                            <span [className]="selected == (i + 1) ? ' selectedItem' : ''">
                                {{ menuTab.name }}
                            </span>
                        </div>

                    </div>
                    <div class="menu responsive">
                        <i class="fa fa-bars cursor-pointer" (click)="abrirResponsive()"></i>
                    </div>
                  </div>
            </div>

        </div>

    </div>

</div>
