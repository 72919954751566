
<div>
  <div *ngIf="!hideBack" class="backButton mb-4">
    <h3 class="text-l font-weight-bolder" *ngIf="community != undefined" [routerLink]="[ '/',community.code , 'events']" queryParamsHandling="merge"><fa-icon [icon]="faArrowLeft"></fa-icon> {{ 'GoToList' | translate }}</h3>
  </div>
  <div *ngIf="isLoading">
    <div>
      <p-skeleton styleClass="w-100" width="10rem" height="20rem"></p-skeleton>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-md-8">
        <p-skeleton styleClass="w-100" width="10rem"></p-skeleton>
        <p-skeleton styleClass="w-75 mt-3" width="10rem"></p-skeleton>
        <p-skeleton styleClass="w-75 mt-2" width="10rem"></p-skeleton>
        <p-skeleton styleClass="w-100 mt-3"></p-skeleton>
        <p-skeleton styleClass="w-100 mt-1"></p-skeleton>
        <p-skeleton styleClass="w-100 mt-1"></p-skeleton>
        <p-skeleton styleClass="w-100 mt-1"></p-skeleton>
        <p-skeleton styleClass="w-100 mt-1"></p-skeleton>
      </div>
      <div class="col-12 col-md-4">
        <p-skeleton styleClass="w-100" height="5rem"></p-skeleton>
        <p-skeleton styleClass="w-25 mt-2"></p-skeleton>
        <p-skeleton styleClass="w-50 mt-1"></p-skeleton>
      </div>
    </div>
  </div>
  <div *ngIf="!isLoading" class="detail-wrapper">
    <div class="image-box">
      <img [src]="event.image ?? defaultImg | imageUrl | async">
      <div *ngIf="!event.dateTo || sameDay" class="event-date one-date">
        <div class="date-number">
          <span>{{ dateFrom | date: 'd' }}</span>
          <span>{{ dateFrom | localizedDate: 'MMMM' }}</span>
        </div>
      </div>
      <div *ngIf="event.dateTo && !sameDay" class="event-date two-dates">
        <div class="date-number">
          <span>{{ dateFrom | date: 'd' }}</span>
          <span>{{ dateFrom | localizedDate: 'MMMM' }}</span>
        </div>
        <span>
          <i class="fa-solid fa-right-long arrow-icon"></i>
        </span>
        <div class="date-number">
          <span>{{ dateTo | date: 'd' }}</span>
          <span>{{ dateTo | localizedDate: 'MMMM' }}</span>
        </div>
      </div>
    </div>
    <div class="white-row p-4">
      <div class="row">
        <div class="col-12 col-md-8">
          <div>
            <span class="category">{{ event.categoryName }}</span>
            <h1 class="ml-0 pl-0 title">{{event.name}}</h1>
          </div>
          <div class="row">
            <div class="col-12 date-info" *ngIf="!isCustom">
              <ng-container *ngIf="sameDay">
                <span *ngIf="event.allDay">
                  <i class="fa fa-calendar info-icon"></i>
                  {{ event.dateFrom | localizedDate: 'EEEE, dd MMMM yyyy'}}
                </span>
                <span *ngIf="!event.allDay">
                  <i class="fa fa-calendar info-icon"></i>
                  {{ event.dateFrom | localizedDate: "EEEE, dd MMMM yyyy HH:mm" }} h - {{ event.dateTo | localizedDate: 'HH:mm' }} h
                </span>
              </ng-container>
              <ng-container *ngIf="!sameDay">
                <div class="date-info">
                  <div>
                    <i class="fa fa-calendar info-icon"></i>
                  </div>
                  <div>
                    <span>
                      {{ 'From' | translate }}
                      {{
                        event.allDay ?
                        (dateFrom  | localizedDate: 'EEEE, dd MMMM yyyy')
                        :
                        (dateFrom | localizedDate: 'EEEE, dd MMMM yyyy HH:mm') + ' h'
                      }}
                    </span>
                    <ng-container *ngIf="event.dateTo">
                      <br>
                      <span>
                        {{ 'To' | translate }}
                        {{
                          event.allDay ?
                          (dateTo | localizedDate : 'EEEE, dd MMMM yyyy')
                          :
                          (dateTo | localizedDate: 'EEEE, dd MMMM yyyy HH:mm') + ' h'
                        }}
                      </span>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="col-12" *ngIf="event.locationString">
              <span class="date-info">
                <i class="fa fa-map-marker info-icon"></i>
                {{ event.locationString }}
              </span>
            </div>
            <div class="col-12">
              <hr>
            </div>
            <div class="col-12">
              <p class="new-inner-body ml-0 pl-0" [innerHTML]="event.description"></p>
              <div *ngIf="event.files.length">
                <b class="mb-2">{{'Fitxers_adjunts' | translate}}</b>
                <div class="col-12 mb-3 mt-1">
                  <app-file-uploader
                    category="events"
                    [viewOnly]=" true"
                    [maxFiles]="5"
                    [type]="1"
                    [(files)]="event.files"
                  ></app-file-uploader>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="event.locationLat && event.locationLng">
              <h3 class="text-lg">
                <strong>{{ 'Location' | translate }}:</strong>
              </h3>
              <span>
                <i class="fa fa-map-marker info-icon"></i>
                {{ event.locationString }}
              </span>
              <app-mapbox
                class="map mt-3"
                [centerlat]="event.locationLat"
                [centerlng]="event.locationLng"
                [lat]="event.locationLat"
                [lng]="event.locationLng"
                [scrollWheelZoom]="true">
              </app-mapbox>
              <button class="how-to-arrive mt-3" (click)="openMaps()">
                <i class="fa fa-map me-1"></i>
                <span>{{ 'HowToArrive' | translate }} </span>
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="sticky-content">
            <button 
              *ngIf="event.ticketId && !isMobile" 
              class="buy-btn" 
              [class.disabled-btn]="!buyButtonState.enabled" 
              [disabled]="!buyButtonState.enabled" 
              (click)="openTicketModal()"
            >
              <strong>
                <i *ngIf="buyButtonState.enabled" class="fa fa-ticket me-1"></i>
                {{buyButtonState.text}}
              </strong>
            </button>
            <div *ngIf="!isCustom && addToCalendarLoaded && canAddCalendar" class="mt-3">
              <a #addCalendar (click)="addToCalendar()" class="calendar-btn-icon">
                <i class="pi pi-calendar-plus"></i>
                <span class="pe-2">{{ 'AddToCalendar' | translate }}</span>
              </a>
            </div>
          </div>
        </div>
    </div>
  </div>
  <div>
    <button 
      *ngIf="event.ticketId && isMobile" 
      class="buy-btn fixed-btn" 
      [class.disabled-btn]="!buyButtonState.enabled" 
      [disabled]="!buyButtonState.enabled" 
      (click)="openTicketModal()"
    >
      <strong>
        <i *ngIf="buyButtonState.enabled" class="fa fa-ticket me-1"></i>
        {{buyButtonState.text}}
      </strong>
    </button>
  </div>
</div>
