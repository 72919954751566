import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from "../../../../services/api.service";
import { CenterDTO } from "../../../../DTO/PriorAppointment/CenterDTO";
import { CommunityDTO } from "../../../../DTO/CommunityDTO";
import { ServiceDTO } from "../../../../DTO/PriorAppointment/ServiceDTO";
import { ReservationDTO } from "../../../../DTO/PriorAppointment/ReservationDTO";
import {
  CheckEnabledDatesDTO,
  HoursWithDataDTO,
  PeriodScheduleDTO,
  ScheduleHourDTO
} from "../../../../DTO/PriorAppointment/PeriodsAndHoursDto";
import { DateTime } from "luxon";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-reservation-step1',
  templateUrl: './reservation-step1.component.html',
  styleUrl: './reservation-step1.component.scss'
})
export class ReservationStep1Component implements OnInit {

  public isReloadingInfo = false;
  public centerDTOs: CenterDTO[] = [];
  public servicesDTOs: ServiceDTO[] = [];
  public scheduleHours: ScheduleHourDTO[] = [];
  public activeService: ServiceDTO;
  public periodHours: number;
  public activeCenter: CenterDTO;
  public selectedDay: Date;
  public minDate: Date;
  public disabledDates: Date[] = [];
  public availableTimes: any[] = [];
  public types: any[] = [];
  public loadingCenters = false;
  public loadingServices = false;
  public activeTime: string;
  public listOfReservedDays: string[];
  public maxDate: Date;
  public loadType = false;
  public loadHours = false;
  @Input() nextAttempt = new EventEmitter();
  @Output() formValid = new EventEmitter<boolean>();
  @Input() public community: CommunityDTO;
  @Input() public reservation: ReservationDTO;
  form: FormGroup;

  constructor(private apiService: ApiService) {
  }

  ngOnInit(): void {
    if (this.reservation?.centerId) {
      this.isReloadingInfo = true;
    }
    this.initializeForm();
    this.fetchCenters();
    this.nextAttempt.subscribe(() => this.validateForm());
  }

  private initializeForm(): void {
    const today = new Date();
    this.minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
    this.form = new FormGroup({
      center: new FormControl(null, Validators.required),
      service: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),
      date: new FormControl(null, Validators.required),
      time: new FormControl(null, Validators.required),
    });

    this.form.valueChanges.subscribe(() => {
      Object.values(this.form.controls).forEach(control => control.markAsUntouched());
    });
  }

  private fetchCenters(): void {
    this.loadingCenters = true;
    this.apiService.getCenters(this.community?.code).subscribe((res: CenterDTO[]) => {
      this.centerDTOs = res;
      this.loadingCenters = false;
      if (this.reservation?.centerId) {
        this.form.patchValue({type: this.reservation.type});
        this.activeCenter = this.centerDTOs.find(x => x.id === this.reservation.centerId);
        if (this.activeCenter) {
          this.form.patchValue({center: this.activeCenter.id});
          this.fetchServicesForCenter();
        }
      }
    });
  }

  private fetchServicesForCenter() {
    if (!this.activeCenter) return;

    this.loadingServices = true;
    this.apiService.getServicesByCenterId(this.community?.code, this.activeCenter.id).subscribe((res: ServiceDTO[]) => {
      this.servicesDTOs = res;
      this.loadingServices = false;
      if (this.reservation?.serviceId) {
        this.activeService = this.servicesDTOs.find(x => x.id === this.reservation.serviceId);
        if (this.activeService) {
          this.apiService.getDatesReserved(this.community?.code, this.activeService.id).subscribe(async (res: string[]) => {
            this.listOfReservedDays = res;
            this.form.patchValue({service: this.activeService});
            this.periodHours = this.activeService.slot;
            this.selectedDay = DateTime.fromISO(this.reservation.startTime).toJSDate();
            await this.showHours();
            this.selectTime(this.formatTime(this.selectedDay));
            this.types = this.getServiceTypes(this.activeService.type);
            await this.checkDisabledDates();
            this.isReloadingInfo = false;
          });
        }
      }
    });
  }
  async checkDisabledDates() {
    let data = new CheckEnabledDatesDTO();
    data.centerId = this.activeCenter.id;
    if (this.activeService.schedule == 0) {
      this.activeService.periodSchedulesObject = await lastValueFrom(this.apiService.getScheduleObject(this.community.code, this.activeService.id));
      data.serviceId = this.activeService.id;
      data.periodSchedulesService = this.activeService.periodSchedulesObject;
      data.hasPeriods = true;
    } else {
      data.hasPeriods = false;
      data.periodSchedulesCenter = this.activeCenter.periodSchedules;
    }
    let c = await lastValueFrom(this.apiService.disabledDates(this.community.code, data));
    this.maxDate = DateTime.fromISO(c.maxDate).toJSDate();
    this.disabledDates = c.disabledDates.map(x => DateTime.fromISO(x).toJSDate());
  }

  setCenter(center: CenterDTO): void {
    this.activeCenter = center;
    this.form.patchValue({center: center.id});
    this.reservation.centerId = center.id;
    this.reservation.centerName = center.name;
    this.reservation.rgpd = center.rgpd;
    this.servicesDTOs = [];
    this.cleanForm(1);
    this.fetchServicesForCenter();
  }

  selectedService(service?: ServiceDTO): void {
    this.activeService = service;
    this.loadType = true;
    this.selectedDay = null;
    if (!this.activeService) return;
    this.apiService.getDatesReserved(this.community?.code, this.activeService.id).subscribe(async (res: string[]) => {
      this.listOfReservedDays = res;
      await this.checkDisabledDates();
      this.types = this.getServiceTypes(this.activeService.type);
      this.availableTimes = [];
      this.periodHours = this.activeService.slot;
      this.form.patchValue({service: this.activeService});
      this.cleanForm(2);
      this.loadType = false;
    });
  }

  selectType(): void {
    this.form.patchValue({type: this.reservation.type});
  }

  async showHours(bool?: boolean) {
    if (!this.activeService || !this.selectedDay) return;
    this.loadHours = true;
    this.cleanForm(3);
    this.scheduleHours = [];
    this.availableTimes = [];
    this.activeTime = '';
    this.form.patchValue({date: this.selectedDay});
    let periodSchdules: any;
    if (this.activeService.schedule === 0) {
      periodSchdules = await lastValueFrom(this.apiService.getScheduleObject(this.community.code, this.activeService.id));
    } else {
      periodSchdules = this.activeCenter.periodSchedules
    }
    const date = new Date(this.selectedDay);
    const period = this.getPeriodForDate(new Date(date.setHours(0, 0, 0, 0)), periodSchdules);
    if (period) {
      const day = this.selectedDay.getDay();
      const hours = period.scheduleDayOfWeeks.find(x => x.dayOfWeek === day)?.scheduleHours;
      if (hours) {
        this.scheduleHours = hours;
        let hoursAndData = new HoursWithDataDTO();
        hoursAndData.hours = hours;
        hoursAndData.periodHours = this.periodHours;
        hoursAndData.reservedDays = this.listOfReservedDays;
        console.log(this.selectedDay);
        hoursAndData.selectedDay = DateTime.fromJSDate(this.selectedDay).toISO();
        hoursAndData.capacity = this.activeService.capacity;
        this.availableTimes = await lastValueFrom(this.apiService.generateAvailableTimes(this.community.code, hoursAndData));
        this.loadHours = false;
      }
    }
  }

  private getPeriodForDate(date: Date, periodSchedules: PeriodScheduleDTO[]): PeriodScheduleDTO | null {
    return periodSchedules.find(period => {
      const start = DateTime.fromISO(period.start).toJSDate();
      const end = DateTime.fromISO(period.end).toJSDate();
      return date >= start && date <= end;
    }) || null;
  }

  private getServiceTypes(type: number): any[] {
    if (type === 0) return [{name: 'Presencial', value: 0}];
    if (type === 1) return [{name: 'Online', value: 1}];
    if (type === 2) return [
      {name: 'Presencial', value: 0},
      {name: 'Online', value: 1}
    ];
    return [];
  }

  selectTime(time: string): void {
    this.activeTime = time;
    this.form.patchValue({time});
    const [hours, minutes] = time.split(':').map(Number);
    const startTimeDate = new Date(this.selectedDay);
    startTimeDate.setHours(hours, minutes, 0);
    const endTimeDate = new Date(startTimeDate);
    endTimeDate.setMinutes(startTimeDate.getMinutes() + this.periodHours);
    this.reservation.startTime = DateTime.fromJSDate(startTimeDate).toISO();
    this.reservation.endTime = DateTime.fromJSDate(endTimeDate).toISO();
  }

  validateForm(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.reservation.centerId = this.activeCenter.id;
      this.reservation.centerName = this.activeCenter.name;
      this.reservation.serviceId = this.activeService.id;
      this.reservation.serviceName = this.activeService.name;
      this.formValid.emit(true);
    }
  }
  formatTime(date: Date): string {
    return `${this.padZero(date.getHours())}:${this.padZero(date.getMinutes())}`;
  }
  padZero(number: number): string {
    return number < 10 ? '0' + number : number.toString();
  }

  cleanForm(level: number): void {
    switch (level) {
      case 1:
        this.form.patchValue({service: null, type: null, time: null, date: null});
        this.activeService = null;
        this.reservation.serviceId = null;
        this.reservation.type = null;
        this.reservation.startTime = null;
        this.reservation.endTime = null;
        break;
      case 2:
        this.form.patchValue({type: null, time: null, date: null});
        this.reservation.type = null;
        this.reservation.startTime = null;
        this.reservation.endTime = null;
        break;
      case 3:
        this.form.patchValue({time: null});
        this.reservation.startTime = null;
        this.reservation.endTime = null;
        break;
      default:
        break;
    }
  }
}
