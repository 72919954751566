<div class="flex-row">
  <!-- CATEGORY -->

  <div *ngIf="(filtersOptions().categories && filtersOptions().categories.length > 0) && !hasDefaultCategory()">
    <p-multiSelect
      styleClass="filter-btn"
      [options]="filtersOptions().categories"
      [(ngModel)]="selectedFilters().categories"
      [placeholder]="'Category' | translate"
      optionLabel="name"
      optionValue="id"
      display="chip"
      (onChange)="updateFilters()"
      (onClear)="updateFilters()"
      [showToggleAll]="true"
      [showClear]="true">
    </p-multiSelect>
  </div>

  <!-- DATE -->

  <!-- <div id="target" #targetEl> -->
    <!-- TO DO: ADD ON CLEAR METHOD -->
    <!-- <p-dropdown
      styleClass="filter-btn date-dropdown"
      #dateDropdown
      [placeholder]="'EVENTS.SelectDate' | translate"
      [options]="dates()"
      [(ngModel)]="selectedFilters().dates"
      optionLabel="label"
      [showClear]="true">
      <ng-template pTemplate="selectedItem">
        <div class="date-option" *ngIf="dateOptionSelected && !isOverlay">
          <i class="calendar-icon"></i>
          {{ dateOptionSelected.label }}
        </div>
        <div *ngIf="dateOptionSelected && isOverlay">
          <div class="date-option" *ngIf="dateOptionSelected.value == 4">
            <i class="calendar-icon"></i>
            {{ dateRanges[0] | date:'dd/MM/yyyy' }}
          </div>
          <div class="date-option" *ngIf="dateOptionSelected.value == 5">
            <i class="calendar-icon"></i>
            {{ dateRanges[0] | date:'dd/MM/yyyy' }}
            <i *ngIf="dateRanges[1]" class="fa fa-long-arrow-right"></i>
            {{ dateRanges[1] | date:'dd/MM/yyyy' }}
          </div>
        </div>
      </ng-template>
      <ng-template let-option pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div (click)="getDateOption(option)">{{ option.label }}</div>
        </div>
      </ng-template>
    </p-dropdown> -->

    <!-- OVERLAYS -->

    <!-- SINGLE DATE CALENDAR -->

    <!-- <p-overlayPanel
      #singleDay >
      <p-calendar
        class="size-s"
        styleClass="size-s"
        [inline]="true"
        [(ngModel)]="singleDateSelected"
        [dateFormat]="'dd/MM/yyyy'"
        (onSelect)="getSingleDate($event)">
      </p-calendar>
    </p-overlayPanel> -->

    <!-- RANGE DATE CALENDAR -->

    <!-- <p-overlayPanel
      #rangeDates>
      <p-calendar
        class="size-s"
        [inline]="true"
        [(ngModel)]="rangedDateSelected"
        [selectionMode]="'range'"
        [dateFormat]="'dd/MM/yyyy'"
        (ngModelChange)="getRangeDates($event)">
      </p-calendar>
    </p-overlayPanel>     -->
  <!-- </div> -->

  <!-- PRICE -->

  <div>
    <p-dropdown
      styleClass="filter-btn price-dropdown"
      [options]="filtersOptions().prices"
      [(ngModel)]="selectedFilters().free"
      optionLabel="label"
      optionValue="value"
      [showClear]="true"
      (onChange)="updateFilters()"
      (onClear)="updateFilters()"
      [placeholder]="'EVENTS.SelectPrice' | translate">
      <ng-template let-option pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>{{ option.label }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
</div>
