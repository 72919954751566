import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
// import { CountryISO, NgxIntlTelInputModule, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-gg';
import { CommunityService } from 'src/app/services/community/community.service';
import { first, map } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { InputTextModule } from 'primeng/inputtext';
import { CountryISO, NgxIntlTelInputModule, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-gg';

@Component({
  selector: 'app-custom-field-phone',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFieldPhoneComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomFieldPhoneComponent),
      multi: true
    }
  ],
  templateUrl: './phone.component.html',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // NgxIntlTelInputModule,,
    InputTextModule,
    TranslateModule,
    NgxIntlTelInputModule
],
  styleUrls: ['../../styles/fields.scss', './phone.component.scss']
})
export class CustomFieldPhoneComponent implements OnInit, ControlValueAccessor{

  @ViewChild('phone') inputNgModel!: NgModel;

  @Input()
  public readonly: boolean;

  public internalValue: string;
  public formattedPhone: any;
  public form: FormGroup;
  public SearchCountryField = SearchCountryField;
  public CountryISO = CountryISO;
  public PhoneNumberFormat = PhoneNumberFormat;
  public preferredCountries: CountryISO[] = [CountryISO.Spain];
  public defaultCountry: any;

  private selectedCountry: string;

  onChange: (_: string) => void = (_: string) => { };

  constructor(private communityService: CommunityService) { }

  ngOnInit(): void {
    this.setPhoneDefaultCountry();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(): void { }

  writeValue(val: string): void {
    if(val && this.readonly) {
      this.setFormattedPhone(val);
    }
    this.internalValue = val;
  }

  validate({ value }: FormControl) {
    if (value) {
      if (!this.readonly && !this.isValid()) {
        return {
          format: true
        }
      }
    }

    return false;
  }

  updateChanges(value: string) {
    this.onChange(value);
  }

  public onPhoneChange(event: any) {
    this.selectedCountry = event?.countryCode;
    this.updateChanges(event?.e164Number);
  }

  private isValid() {
    return this.inputNgModel.valid;
  }

  private setPhoneDefaultCountry() {
    this.communityService.GetParameters()
      .pipe(
        first(),
        map(x => {
          if (x['PHONE_DEFAULT_COUNTRY_CODE']) {
            const defaultCode = x['PHONE_DEFAULT_COUNTRY_CODE'];
            const enumValues = Object.values(CountryISO);

            if (enumValues.includes(defaultCode)){
              this.defaultCountry = defaultCode as CountryISO[keyof CountryISO]
            }

            if(!this.preferredCountries.includes(defaultCode)) {
              this.preferredCountries.push(defaultCode);
            }
          } else {
            this.defaultCountry = this.preferredCountries[0];
          }
        })
      ).subscribe()
  }

  private setFormattedPhone(phone: string) {
    const parsedPhone = parsePhoneNumberFromString(phone, this.selectedCountry || this.defaultCountry);
    this.formattedPhone = parsedPhone.formatNational();
  }
}
