import { CommonModule } from '@angular/common';
import { Component, input, Input, InputSignal, model, OnInit, signal, WritableSignal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CategoryDTO } from 'src/app/DTO/CategoryDTO';
import { FilterOptionDTO } from 'src/app/DTO/filters/FilterOptionDTO';

@Component({
  selector: 'app-events-filters',
  standalone: true,
  imports: [CommonModule, TranslateModule, MultiSelectModule, DropdownModule, CalendarModule, OverlayPanelModule, FormsModule],
  templateUrl: './events-filters.component.html',
  styleUrl: './events-filters.component.scss'
})
export class EventsFiltersComponent implements OnInit {

  public hasDefaultCategory = input<boolean>(false);
  public filtersOptions = input<any>({});
  public selectedFilters = model({
    categories: [],
    free: null
  });

  ngOnInit(): void { }

  public updateFilters() {
    this.selectedFilters.update((filters) => ({ ... filters }))
  }
}
