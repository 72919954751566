<ng-container *ngIf="!readonly">
  <app-file-uploader        
    [(ngModel)]="files"                
    [maxFiles]="1"    
    [type]="1"            
    (ngModelChange)="onFileChange($event)"
    [category]="'inscriptions'">        
  </app-file-uploader>
</ng-container>

<ng-container *ngIf="readonly">  
  <app-file-uploader
    [type]="1"
    [ngModel]="files"
    [viewOnly]="true">    
  </app-file-uploader>
</ng-container>