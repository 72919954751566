import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from "primeng/inputtext";
import { CommonModule } from "@angular/common";

@Component({
  selector: 'app-custom-field-email',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFieldEmailComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomFieldEmailComponent),
      multi: true
    }
  ],
  templateUrl: './email.component.html',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
],
  styleUrls: ['../../styles/fields.scss']
})
export class CustomFieldEmailComponent implements OnInit, ControlValueAccessor{

  @Input()
  public readonly: boolean;

  public internalValue: any;

  private readonly mailRegex: RegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;


  onChange: (_: string) => void = (_: string) => { };

  constructor() { }

  ngOnInit(): void { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void { }

  writeValue(val: string): void {
    this.internalValue =val;

    if(val) {
      this.updateChanges(val)
    }
  }

  validate({ value }: FormControl) {
    if (value) {
      if (!this.isValid(value)) {
        return {
          format: true
        }
      }
    }

    return false;
  }

  public updateChanges(value: string) {
    this.onChange(value);
  }

  private isValid(mail: string): boolean {
    return this.mailRegex.test(mail);
  }
}
