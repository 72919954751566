<div class="step-1-wrapper" [ngStyle]="{ '--bg-color': community?.color1 ? community.color1  : '#3B82F6'}">
    <form [formGroup]="form" *ngIf="!isReloadingInfo">
        <div class="row">
            <div class="col-12 col-md-6">
                <div>
                    <h4 class="fw-600 mb-3">{{ 'PRIORAPPOINTMENT.center' | translate }}</h4>
                    <div class="d-flex mb-5" *ngIf="!this.loadingCenters">
                        <button *ngFor="let center of centerDTOs"
                                class="btn rounded py-2 px-3 me-4 d-flex align-items-center"
                                (click)="setCenter(center)"
                                [style.background-color]="center.id === activeCenter?.id ? community?.color1 : 'white'"
                                [style.color]="center.id === activeCenter?.id ? 'white' : ''">
                            <ng-container *ngIf="center.path && center.id === activeCenter?.id"  >
                                <app-convert-svg [currentColor]="'white'" [path]="center.path | imageUrl | async"></app-convert-svg><span class="ms-2">{{ center.name }}</span>
                            </ng-container>
                            <ng-container *ngIf="center.path && center.id !== activeCenter?.id" >
                                <app-convert-svg [currentColor]="'black'" [path]="center.path | imageUrl | async"></app-convert-svg><span class="ms-2">{{ center.name }}</span>
                            </ng-container>
                            <ng-container *ngIf="!center.path">
                                <i class="fa fa-house"></i><span class="ms-2">{{ center.name }}</span>
                            </ng-container>

                        </button>
                    </div>
                    <p-skeleton *ngIf="this.loadingCenters" styleClass="w-100" height="4rem"></p-skeleton>
                    <p *ngIf="form.controls['center'].invalid && form.controls['center'].touched" class="text-danger">
                        {{ 'PRIORAPPOINTMENT.select_center_error' | translate }}
                    </p>
                    <p *ngIf="!this.loadingCenters && !this.centerDTOs?.length"> {{'PRIORAPPOINTMENT.notCentersConfiguredYet' | translate}}</p>
                </div>
                <div *ngIf="activeCenter">
                    <h4 class="fw-600 mb-3">{{ 'PRIORAPPOINTMENT.service' | translate }}</h4>
                    <div class="d-flex mb-5" *ngIf="!loadingServices">
                        <button *ngFor="let service of servicesDTOs"
                                [style.background-color]="service.id === activeService?.id ? community?.color1 : 'white'"
                                [style.color]="service.id === activeService?.id ? 'white' : ''"
                                class="btn rounded py-2 px-3 me-4 d-flex align-items-center fw-500"
                                (click)="selectedService(service)">


                                <span class="">{{ service.name }}</span>


                        </button>

                        <p *ngIf="!servicesDTOs.length" class="">
                            {{ 'PRIORAPPOINTMENT.no_services' | translate }}
                        </p>
                        <p *ngIf="form.controls['service'].invalid && form.controls['service'].touched && servicesDTOs.length" class="text-danger">
                            {{ 'PRIORAPPOINTMENT.select_service_error' | translate }}
                        </p>
                    </div>
                    <div *ngIf="this.loadingServices" class="d-flex">
                        <p-skeleton width="150px" height="3rem" class="me-3"></p-skeleton>
                        <p-skeleton width="150px" height="3rem" class="me-3"></p-skeleton>
                        <p-skeleton width="150px" height="3rem"></p-skeleton>
                    </div>

                </div>
                <div *ngIf="activeService">
                    <h4 class="fw-600 mb-3">{{ 'PRIORAPPOINTMENT.appointment_type' | translate }}</h4>
                    <div class="mb-4" *ngIf="!loadType">
                        <p-selectButton
                                [options]="types"
                                [ngModelOptions]="{ standalone: true }"
                                [(ngModel)]="reservation.type"
                                (onChange)="selectType()"
                                optionLabel="name"
                                optionValue="value" />
                        <p *ngIf="form.controls['type'].invalid && form.controls['type'].touched" class="text-danger">
                            {{ 'PRIORAPPOINTMENT.select_type_error' | translate }}
                        </p>
                    </div>

                </div>
                <p-skeleton *ngIf="this.loadType" width="150px" class="d-block mb-2" height="4rem"></p-skeleton>
            </div>
            <div class="col-12 col-md-6">
                <div *ngIf="reservation.type != null && activeCenter && activeService">
                    <h4 class="fw-600 mb-3">{{ 'PRIORAPPOINTMENT.date_time' | translate }}</h4>
                    <p-calendar
                            class="max-w-full small-calendar"
                            [(ngModel)]="selectedDay"
                            [ngModelOptions]="{ standalone: true }"
                            [disabledDates]="disabledDates"
                            (onSelect)="showHours()"
                            [inline]="true"
                            [showWeek]="true"
                            [minDate]="minDate"
                            [maxDate]="maxDate"/>
                    <p *ngIf="form.controls['date'].invalid && form.controls['date'].touched" class="text-danger">
                        {{ 'PRIORAPPOINTMENT.select_date_error' | translate }}
                    </p>
                    <div class="row mt-3 p-0 m-0">
                        <div class="col-4 col-md-2 my-2 " *ngFor="let time of availableTimes; let i = index" [ngClass]="i%6==0 ? 'pe-1 ps-0' : 'px-1'" >
                            <button class="btn py-1 px-3 rounded border w-100"
                                    [style.color]="time.time === activeTime ? 'white': ''"
                                    [style.background-color]="time.time === activeTime ? community?.color1 : 'white'"
                                    [disabled]="time.reserved"
                                    (click)="selectTime(time.time)">
                                {{ time.time }}h
                            </button>
                        </div>
                        <ng-container *ngIf="loadHours">
                        <div class="col-4 col-md-2 my-2 px-1 "  *ngFor="let i of [1,2,3,4,5,6]">
                            <p-skeleton width="100" class="d-block " height="2rem"></p-skeleton>
                        </div>
                        </ng-container>

                    </div>
                    <p *ngIf="form.controls['time'].invalid && form.controls['time'].touched" class="text-danger">
                        {{ 'PRIORAPPOINTMENT.select_time_error' | translate }}
                    </p>
                </div>
            </div>
        </div>
    </form>
    <p-skeleton *ngIf="this.isReloadingInfo" styleClass="w-100" height="20rem"></p-skeleton>
</div>
