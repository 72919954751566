import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { TranslateModule } from '@ngx-translate/core';
import { InputSwitchModule } from 'primeng/inputswitch';

@Component({
  selector: 'app-custom-field-checkbox',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFieldCheckboxComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomFieldCheckboxComponent),
      multi: true
    }
  ],
  templateUrl: './checkbox.component.html',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputSwitchModule,
    TranslateModule
  ],
  styleUrls: ['../../styles/fields.scss']
})
export class CustomFieldCheckboxComponent implements OnInit, ControlValueAccessor {

  @Input()
  public readonly: boolean;

  public internalValue: any;

  onChange: (_: string) => void = (_: string) => { };

  constructor() { }

  ngOnInit(): void { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(): void { }

  writeValue(val: string): void {
    this.internalValue = val && val === 'true';
  }

  validate(): any {
    return false;
  }

  updateChanges(value: any): void {
    this.onChange(value.toString());
  }
}
