<div class="credit-card">
    <div class="card-container">
        <div #cardInfo></div>
    </div>
</div>
<div *ngIf="totalPrice != 0" [class.d-none]="paymentStarted">
  <div class="d-flex flex-column align-items-end gap-1">

    @if(!firstPayment) {
      <div class="total-price">
        <span>{{ 'Total' | translate }}:</span>
        <strong>{{ totalPrice | currency:'€' }}</strong>
      </div>
    } @else {

      @if(firstPayment.fee > 0) {
        <span>
          <span class="fee-text">
            {{ 'Subtotal' | translate }}:
          </span>
          {{ firstPayment.total | currency: 'EUR' }}
        </span>
        <span>
          <span class="fee-text">{{ 'Fee' | translate }}:</span>
          {{ firstPayment.fee | currency: 'EUR'}}
        </span>
      }

      <div class="total-price">
        <span>{{ 'Total' | translate }}:</span>
        <strong>{{ firstPayment.totalWithFee| currency:'€' }}</strong>
      </div>

      @if(firstPayment.taxAmount > 0) {
        <div>
          <span class="fee-text">{{ firstPayment.taxName }} {{ 'Included' | translate | lowercase }}</span>
        </div>
      }
    }
  </div>
</div>
<div class="mt-3 buy-box">
    <button
        type="submit"
        class="btn-buy"
        (click)="checkoutPayment()"
        [class.disabled-btn]="!filled || paymentStarted"
        [style.background-color]="corpColor">
        @if (!paymentStarted){
            {{ 'Pay' | translate }}
        } @else {
            <app-spinner size="s"></app-spinner>
        }
    </button>
</div>
