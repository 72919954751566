import { Pipe, PipeTransform } from '@angular/core';
import {BehaviorSubject, map, Observable} from "rxjs";
import {FilesService} from "../services/files.service";

@Pipe({
  name: 'imageUrl'
})
export class ImageUrlPipe implements PipeTransform {
  constructor(private filesService: FilesService) {}
  transform(value: string , type?: string): Observable<string> {
    const subject = new BehaviorSubject<string>('');

    if (!value || value.startsWith('http')) {
      subject.next(value);
      subject.complete();
    } else {
      this.filesService.getURL(value).pipe(
          map(response => {
            subject.next(response);
            subject.complete();
          })
      ).subscribe();
    }

    return subject.asObservable();
  }

}
