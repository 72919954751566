<ng-container *ngIf="!readonly">
  <ngx-intl-tel-input
    [preferredCountries]="preferredCountries"
    [enableAutoCountrySelect]="true"
    [searchCountryFlag]="true"
    [searchCountryField]="[
      SearchCountryField.All
    ]"
    [selectFirstCountry]="false"
    [selectedCountryISO]="defaultCountry"
    [numberFormat]="PhoneNumberFormat.National"
    [searchCountryPlaceholder]="'Placeholders.SearchCountry' | translate"
    [maxLength]="15"
    type="tel"
    [phoneValidation]="true"
    [(ngModel)]="internalValue"
    #phone="ngModel"
    (ngModelChange)="onPhoneChange($event)">
  </ngx-intl-tel-input>
</ng-container>

<ng-container *ngIf="readonly">
  <a class="phone-link" href="tel:{{ internalValue }}">
    <span>{{ formattedPhone }}</span>
  </a>
</ng-container>
