import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from "primeng/inputtext";
import { CommonModule } from "@angular/common";

@Component({
  selector: 'app-custom-field-nif',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFieldNif),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomFieldNif),
      multi: true
    }
  ],
  templateUrl: './nif.component.html',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
],
  styleUrls: ['../../styles/fields.scss']
})
export class CustomFieldNif implements OnInit, ControlValueAccessor{

  @Input()
  public readonly: boolean;

  public internalValue: string;

  onChange: (_: string) => void = (_: string) => { };

  constructor() { }

  ngOnInit(): void { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(val: string): void {
    this.internalValue = val;
  }

  validate({ value }: FormControl) {
    if (value) {
      // DNI
      if (!this.nifValido(value)) {
        return {
          format: true
        }
      }
    }

    return false;
  }

  updateChanges(value: string) {
    this.onChange(value);
  }

  public nifValido(nif: string): boolean {
    nif = nif.toUpperCase();

    const nifRegEx = /^[0-9]{8}[A-Z]$/i;
    const nieRegEx = /^[XYZ][0-9]{7}[A-Z]$/i;
    const letras = "TRWAGMYFPDXBNJZSQVHLCKE";

    if (nifRegEx.test(nif)) {
      return letras[parseInt(nif.substring(0, 8)) % 23] === nif[8];
    } else if (nieRegEx.test(nif)) {
      const firstChar = nif[0];
      if (firstChar === "X") nif = "0" + nif.substring(1);
      else if (firstChar === "Y") nif = "1" + nif.substring(1);
      else if (firstChar === "Z") nif = "2" + nif.substring(1);

      return letras[parseInt(nif.substring(0, 8)) % 23] === nif[8];
    } else {
      return false;
    }
  }
}
