import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewDTO } from 'src/app/DTO/NewDTO';
import { ApiService } from 'src/app/services/api.service';
import { Title } from '@angular/platform-browser';


import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { MetadataService } from 'src/app/services/metadata.service';
import { ImageUrlPipe } from 'src/app/pipes/image-url.pipe';
import { Functions } from 'src/app/helpers/Functions';
@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit {

  newId: string = "";
  new?: NewDTO;
  communityCode: string = "";

  faArrowLeft = faArrowLeft;

  constructor(
    private apiService: ApiService,
    private actRoute: ActivatedRoute,
    private titleService: Title,
    private metadataService: MetadataService,
    private _pipeIMG: ImageUrlPipe,
    ) {
   
  }

  ngOnInit(): void {
    this.newId = this.actRoute.snapshot.params['id'];
    this.communityCode = this.actRoute.snapshot.params['community'];

    this.apiService.getNewById(this.newId, this.communityCode)
    .subscribe(responseData => {
      if(responseData){
        this.new = responseData;

        if (responseData.image != undefined && responseData.title != undefined) {
          
          //this.new.image = this.imageUrl(responseData?.image);
          this.titleService.setTitle(this.new?.title);

          var summary = "";

          if(this.new.summary){
            if(this.new.summary.length > 160){
              summary = this.new.summary.substring(0,160) + "...";
            }else{
              summary = this.new.summary;
            }
          }

          this._pipeIMG.transform(responseData?.image).subscribe(x=>{
            let absoluteImageUrl = x;
            this.metadataService.updateMetadata({
              title: Functions.decode(this.new?.title),
              description: Functions.decode(summary),
              image: absoluteImageUrl ? Functions.decode(absoluteImageUrl) : null
            });
          });


        }
      }

    });

  }

  ngOnDestroy(): void {
    // Quitar el tag "page.info" antes de ir a otra página, para que rastree correctamente la información
    //this.metaTagService.removeTag('name=\'og:title\'');
    //this.metaTagService.removeTag('name=\'og:image\'');
  }

}
