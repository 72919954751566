<div class="page-content">
  <div class="row pb-3">
    <div class="col-sm-6" *ngIf="!isIframe">
      <h2 *ngIf="!isCustom" class="text-xl font-weight-bolder">{{ title }}</h2>
      <h2 *ngIf="isCustom" class="text-xl font-weight-bolder">{{ 'EVENTS.Entrances' | translate }}</h2>
    </div>
  </div>

  @if($optionsLoaded() || !$loading()) {
    <app-events-filters
      [hasDefaultCategory]="categoryParam !== null"
      [filtersOptions]="filtersOptions"
      [(selectedFilters)]="selectedFilters"
    />
  }

  @if(!$loading()) {
    <div *ngIf="tickets().length > 0 else noTickets">
      <h3 *ngIf="!isCustom" class="text-sm mb-1">{{ 'EVENTS.Next' | translate }}</h3>
      <div class="row">
        <app-event-card
          *ngFor="let ticket of tickets()"
          [routerLink]="[ticket.id]" queryParamsHandling="merge"
          [isCustomCommunity]="isCustom"
          class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3"
          [ticket]="ticket"
          queryParamsHandling="merge">
        </app-event-card>
      </div>
    </div>

    <ng-template #noTickets>
      <app-empty-state
        icon="fa fa-calendar"
        [text]="'TICKETING.No_Tickets' | translate" />
    </ng-template>
  } @else {
    @if(!$optionsLoaded()) {
      <div class="row">
        <div class="col-md-6 col-12">
          <p-skeleton styleClass="w-100" borderRadius="22px" height="45px"></p-skeleton>
        </div>
      </div>
    }
    <p-skeleton styleClass="w-25 mt-4"></p-skeleton>
    <div class="row mt-2">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3 ">
        <p-skeleton styleClass="w-100" height="250px"></p-skeleton>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
        <p-skeleton styleClass="w-100" height="250px"></p-skeleton>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
        <p-skeleton styleClass="w-100" height="250px"></p-skeleton>
      </div>
    </div>
    <p-skeleton styleClass="w-25 mt-5"></p-skeleton>
    <div class="row mt-2">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
        <p-skeleton styleClass="w-100" height="250px"></p-skeleton>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
        <p-skeleton styleClass="w-100" height="250px"></p-skeleton>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
        <p-skeleton styleClass="w-100" height="250px"></p-skeleton>
      </div>
    </div>
  }

  <div class="paginator-wrapper mt-4">
    <p-paginator
      styleClass="custom-paginator"
      #paginatorElement
      (onPageChange)="pageChange($event)"
      [first]="page"
      [rows]="rows"
      [totalRecords]="collectionSize" />
  </div>
</div>

