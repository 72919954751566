import { TicketPriceDTO } from "./TicketPriceDTO";
import {UploadedFileModel} from "../components/file-uploader/file-uploader.component";

export class EsdevenimentDTO{
  _images: string [] = [];
  image: string = "";
  id: number = 0;
  slug?: string;
  name?: string;
  description?: string;
  dateFrom: Date = new Date();
  dateTo?: Date;
  allDay?: boolean;
  locationString?: string;
  phone:string = "";
  mail?:string;
  eventCategoryName?: string;
  active?:boolean;
  summary: string;
  onlyhours: boolean;
  ticketId: number;
  ticketPrices: TicketPriceDTO[];
  featured: boolean;
  categoryId?: number;
  categoryName: string;
  locationLat: number;
  locationLng: number;
  sameDay: boolean;
  files: UploadedFileModel[] = [];
}
