<ng-container *ngIf="!readonly">
  <textarea
    pInputTextarea
    [ngModel]="internalValue"
    (ngModelChange)="updateChanges($event)"
    class="w-100">
  </textarea>
</ng-container>

<ng-container *ngIf="readonly">
  <span>{{ internalValue }}</span>
</ng-container>