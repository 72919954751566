<div class="wrapper-action-detail container  pb-5">
    <a class="text-black my-4 cursor-pointer d-block" (click)="goBack()"><i class="fa fa-arrow-left me-1"></i> {{ 'Back' | translate }}</a>
    <div class="bg-white px-2 py-3 row rounded ">
        <div class="w-100 row-axe d-flex flex-column col-12">
            <div class="d-flex flex-column flex-md-row" *ngIf="!loading">
                <div class="img-box position-relative rounded" [style.background-image]="'url(' + (actionDTO?.axisImage | imageUrl | async) + ')'">
                </div>
                <div class="w-70 px-0 px-md-4 py-3 d-flex flex-column">
                    <div class="d-flex flex-row">
                        <p class="mb-0 mb-lg-1 me-3" style="font-weight: 600" [style.color]="community?.color1 ? community.color1 : '#17A1FA'">{{'PAM.axis' | translate}} {{actionDTO.axisOrder +1}}:</p>
                        <p class="mb-0">{{actionDTO.axisName}}</p>
                    </div>
                    <div class="d-flex my-3">
                        <app-convert-svg
                                class="me-2 pt-1"
                                [path]="'assets/icons/diana.svg'"
                                [currentColor]="community?.color1 ? community.color1 : '#17A1FA'"
                                [width]="'20px'"
                                [height]="'20px'"
                        > </app-convert-svg>
                        <h5 [style.color]="community?.color1 ? community.color1 : '#17A1FA'" *ngIf="!loading">
                             {{actionDTO.name}}</h5>
                    </div>
                    <div class="d-flex flex-row align-items-md-center" >
                        <span class="me-3 mb-3 mb-md-0 fw-600">ODS:</span>
                        <div class="d-flex align-items-center" *ngIf="!loading">
                            <div *ngFor="let ods of actionDTO.ods" class="d-flex align-items-center">
                                <img class="me-2" src="assets/icons/ODSPAM/{{ods.image}}" style="width: 35px" />
                            </div>

                        </div>
                        <p-skeleton *ngIf="loading" styleClass="mb-2"  height="40px" width="400px"></p-skeleton>

                    </div>
                </div>

            </div>
            <p-skeleton *ngIf="loading" styleClass="mb-2"  height="200px" width="100%"></p-skeleton>
        </div>



        <div class="col-6 mt-4">
            <div class="d-flex flex-column flex-md-row align-items-md-center" >
                <span class="fw-600 mb-2 mb-md-0 me-3">{{'PAM.status' | translate}}</span>
                <span class="status px-3 py-1 rounded text-white mb-2 mb-md-0" *ngIf="!loading" [ngClass]="actionDTO.progress === 0 ? 'red' : actionDTO.progress === 100 ? 'green' : 'yellow'">
                    <span *ngIf="actionDTO.progress === 0"> {{'PAM.notStarted' | translate}}</span>
                    <span *ngIf="actionDTO.progress === 100"> {{'PAM.completed' | translate}}</span>
                    <span *ngIf="actionDTO.progress > 0 && actionDTO.progress < 100"> {{'PAM.inProgress' | translate}}</span>
                </span>
                <p-skeleton *ngIf="loading" styleClass="mb-2"  height="40px" width="150px"></p-skeleton>

            </div>
        </div>
        <div class="col-6 mt-4">
            <div class="d-flex flex-column flex-md-row align-items-md-center" >
                <div class="d-flex">
                <span class="fw-600 mb-2 mb-md-0 me-2"> {{'PAM.progress' | translate}} </span>
                <p class="mb-2 mb-md-0 mx-md-3" *ngIf="!loading">{{this.actionDTO.progress.toFixed(2)}}%</p>
                </div>
                <p-progressBar *ngIf="!loading" [value]="actionDTO.progress"></p-progressBar>
                <p-skeleton *ngIf="loading" styleClass="mb-2 ms-3"  height="40px" width="150px"></p-skeleton>
            </div>
        </div>
        <div class="col-12">
            <hr class="my-4" [style.color]="community?.color1 ? community.color1 : '#17A1FA'">
        </div>

        <div class="col-6">
            <div class="d-flex flex-column flex-md-row align-items-md-center" >
                <span class="fw-600 me-3 mb-3 mb-md-0"> {{'PAM.type' | translate}} </span>
                <div class="d-flex flex-row flex-wrap" *ngIf="!loading">
                    <div *ngFor="let type of actionDTO.types" class="d-flex align-items-center me-2 px-3 py-1 rounded  mb-3 mb-md-0"
                         [style.background-color]="getColorWithOpacity(type.color , 0.2)"
                          >
                        <app-convert-svg [height]="'20px'"
                                         [width]="'20px'"
                                         [path]="type.path | imageUrl | async"
                                         [currentColor]="type.color"
                                  > </app-convert-svg>
                        <div class="ms-2 fw-500" [style.color]="type.color">{{ type.name }}</div>
                    </div>

                </div>
                <p-skeleton *ngIf="loading" styleClass="mb-2"  height="40px" width="250px"></p-skeleton>

            </div>
        </div>
        <div class="col-6">
            <div class="d-flex flex-column flex-md-row align-items-md-center" >
                <span class="fw-600 me-3 mb-3 mb-md-0"> {{'PAM.areas' | translate}} </span>
                <div class="d-flex flex-row flex-wrap" *ngIf="!loading">
                    <div *ngFor="let areas of actionDTO.areas   " class="d-flex mb-3 mb-md-0 align-items-center me-2 px-3 py-1 rounded"
                        [style.background-color]="getColorWithOpacity(areas.color , 0.2)">
                        <app-convert-svg [height]="'20px'"
                                         [width]="'20px'"
                                         [path]="areas.path | imageUrl | async"
                                         [currentColor]="areas.color"
                        > </app-convert-svg>
                        <div class="ms-2 fw-500" [style.color]="areas.color">{{ areas.name }}</div>
                    </div>
                </div>
                <p-skeleton *ngIf="loading" styleClass="mb-2"  height="40px" width="250px"></p-skeleton>
            </div>
        </div>

        <div class="col-12">
            <hr class="my-4" [style.color]="community?.color1 ? community.color1 : '#17A1FA'">
        </div>

        <div class="col-12 col-md-6 ">
            <div class="d-flex align-items-md-center flex-column flex-md-row align-items-md-center" >
                <span class="fw-600 me-3 mb-3 mb-md-0"> {{'PAM.associatedBudgets' | translate}} </span>
                <div class="d-flex flex-row align-items-md-center" *ngIf="!loading">
                    <div class="budget-pill rounded border me-2 px-3 py-1 mb-3 mb-md-0" *ngFor="let budget of actionDTO.budgets?.slice(0, 2)"> {{budget.year | date:'yyyy'}}</div>
                    <span *ngIf="actionDTO.budgets?.length > 2"
                          class="budget-pill rounded border me-2 px-3 py-1"
                          tooltipStyleClass="budgets-tooltip"
                          [pTooltip]="remainingBudgetsTooltip">
                  +{{ actionDTO.budgets.length - 2 }}
                </span>
                    <ng-template #remainingBudgetsTooltip>
                        <div *ngFor="let budget of actionDTO.budgets?.slice(2)" style=" background-color: #015afb21;" class=" rounded border my-2 px-3 py-1">
                            <span class="text-white">{{ budget.year | date: 'yyyy' }}</span>
                        </div>
                    </ng-template>
                </div>
                <p-skeleton *ngIf="loading" styleClass="mb-2"  height="40px" width="250px"></p-skeleton>

            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="d-flex flex-row align-items-md-center" >
                <span class="fw-600 mb-3 mb-md-0"> {{'PAM.expectedCost' | translate}} </span>
                <p class="mb-0 mx-3 mb-md-0" *ngIf="!loading">{{this.actionDTO.expectedCost}}€</p>
                <p-skeleton *ngIf="loading" styleClass="mb-2 ms-3"  height="40px" width="250px"></p-skeleton>

            </div>
        </div>
        <div class="col-12" *ngIf="actionDTO.comments">
            <hr class="my-4" [style.color]="community?.color1 ? community.color1 : '#17A1FA'">
        </div>
        <div class="col-12" *ngIf="actionDTO.comments">
            <div class="d-flex align-items-center" >
                <span class="me-3 fw-600" > {{'PAM.comments' | translate}} </span>
                <div *ngIf="!loading" [innerHTML]="actionDTO.comments" class="commments"></div>
                <p-skeleton *ngIf="loading" styleClass="mb-2"  height="40px" width="250px"></p-skeleton>
            </div>
        </div>
        <div class="col-12">
            <hr class="my-4" [style.color]="community?.color1 ? community.color1 : '#17A1FA'">
        </div>
        <div class="col-12 mt-4">
            <h4 class="mb-4" *ngIf="actionDTO?.actuations.length" >{{'PAM.actuations' | translate}} </h4>
            <p-table *ngIf=" actionDTO?.actuations.length && !loading" [value]="actionDTO.actuations" class="table-actuations">
                <ng-template pTemplate="header">
                    <tr>
                        <th [style.color]="community?.color1 ? community.color1 : '#17A1FA'">{{'PAM.status' | translate}}</th>
                        <th [style.color]="community?.color1 ? community.color1 : '#17A1FA'">{{'PAM.actuations' | translate}}</th>
                        <th [style.color]="community?.color1 ? community.color1 : '#17A1FA'">{{'PAM.date' | translate}}</th>
                        <th [style.color]="community?.color1 ? community.color1 : '#17A1FA'">{{'PAM.progress' | translate}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-actuation>
                    <tr>
                        <td><app-status-color [percent]="actuation.percent"></app-status-color></td>
                        <td class="fw-500">{{ actuation.name }}</td>
                        <td>{{ actuation.date | date: 'dd/MM/yyyy' }}</td>
                        <td>{{ actuation.percent.toFixed(2) }}%</td>
                    </tr>
                </ng-template>
            </p-table>
            <p-skeleton *ngIf="loading" styleClass="mb-2"  height="200px" width="100%"></p-skeleton>

        </div>
        <div class="col-12 mt-5 ">
                <h4 class="mb-4" *ngIf="actionDTO?.coords.length" >{{'PAM.locations' | translate}} </h4>
                    <app-location-selector
                            *ngIf="actionDTO?.coords.length && !loading"
                            [coordsPam]="this.actionDTO.coords"
                            [showOnlyMap]="true"
                    >
                    </app-location-selector>
                <p-skeleton *ngIf="loading" styleClass="mb-2"  height="400px" width="100%"></p-skeleton>
        </div>
        <div class="col-12 mt-5">
            <h4 class="mb-4" *ngIf="actionDTO?.news.length">{{'PAM.relatedNews' | translate}}</h4>
            <p-skeleton *ngIf="loading" styleClass="mb-2"  height="200px" width="600px"></p-skeleton>
                <div class="row" *ngIf="!loading" >
                    <div class="col-lg-6 mb-3 cursor-pointer " (click)="routeNew(destacat.slug)" *ngFor="let destacat of actionDTO.news; let i = index">
                        <div class="card mb-3 new">
                            <div class="row">
                                <div class="col-md-4 bg-image-card " [ngStyle]="{'background-image': 'url(' + (destacat.image | imageUrl | async)+')'}">
                                </div>
                                <div class="col-md-8 ps-0 ">
                                    <div class="card-body fixh-card-body p-3">
                                        <div class="row">
                                            <div class="col-6">
                                                <p class="card-title text-xxs mb-3 font-italic text-start">{{destacat.publishDate | date:"dd/MM/yyyy"}}
                                                </p>
                                            </div>
                                        </div>
                                        <h5 class=" text-xxs text-justify">{{destacat.title}}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <h4 *ngIf="actionDTO?.files.length"> {{'PAM.attachedFiles' | translate}}</h4>
        <div class="col-12" *ngIf="!loading">

            <div  *ngFor="let archive of actionDTO.files" class="mt-2">
                <i class="fa-solid fa-download me-2"></i><a class="text-decoration-underline" target="_blank" href="{{archive.path | fileUrl}}">{{archive.originalName}}</a>
            </div>
        </div>
        <p-skeleton *ngIf="loading" styleClass="mb-2"  height="40px" width="200px"></p-skeleton>

    </div>
</div>
