import { Component, Input, OnDestroy, Output, OnInit, EventEmitter, AfterViewChecked } from '@angular/core';
import { CommunityService } from 'src/app/services/community/community.service';
import { TicketsService } from '../services/tickets.service';
import { TicketDTO } from 'src/app/DTO/TicketDTO';
import { TicketPriceDTO } from 'src/app/DTO/TicketPriceDTO';
import { DateIsBetweenResult, Helpers } from 'src/app/helpers';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
})
export class TicketsComponent implements OnInit, OnDestroy, AfterViewChecked {

  @Input() ticket: TicketDTO;
  @Output() emitNext: EventEmitter<any> = new EventEmitter<any>();
  @Output() swiperLoaded = new EventEmitter<boolean>();
  @Output() onAvailabilityChanged = new EventEmitter<number>();

  public isLoading = true;
  public numTickets: number = 0;
  public total = 0;
  public totalQty = 0;
  public totalQtyPurchases = 0;
  public maxTiquetsPerUser: number;
  public fullFree = false;
  public inTime = false;
  public corpColor: string;
  public dateFrom: DateTime;
  public dateTo: DateTime;
  public availableTickets: number | null;
  public availableByPrice: { [id: number]: any } = {}
  public maxPerPrice: { [id: number]: number } = {}

  private communityId: number;
  private refreshRemainingInterval: any;

  constructor(private communityService: CommunityService,
              private ticketsService: TicketsService) {}

  ngOnInit(): void {
    this.init();
    this.refreshAvailability();
    this.refreshRemainingInterval = setInterval(() => {
      this.refreshAvailability();
    }, 10000);
  }

  ngOnDestroy() {
    clearInterval(this.refreshRemainingInterval);
  }

  ngAfterViewChecked(): void {

  }

  public onChangeQty(event, p: TicketPriceDTO) {
    p.qty = event;
    p.totalPerQty = p.totalWithFee * p.qty;

    this.total = this.ticket.prices
      .reduce((sum, current) => sum + (current.totalPerQty || 0), 0);
    this.totalQty = this.ticket.prices
      .reduce((sum, current) => sum + (current.qty || 0), 0);

    this.emitData();
  }

  public emitData() {
    const data = {
      total: this.total,
      totalQty: this.totalQty
    };

    this.emitNext.emit(data);
  }

  private init() {
    this.communityService.Get()
      .subscribe((result) => {
        this.communityId = result.id;
        this.corpColor = result.color1;

        this.dateFrom = DateTime.fromJSDate(new Date(this.ticket.saleDateFrom));
        this.dateTo = DateTime.fromJSDate(new Date(this.ticket.saleDateTo));

        this.ticket.prices.forEach((x) => {
          x.totalPerQty = 0;
          x.qty = 0;
        })
        this.fullFree = this.ticket.prices.every(x => x.totalWithFee == 0);
        let chkDates = Helpers.CheckIfDateIsBetween(this.ticket.saleDateFrom, this.ticket.saleDateTo);
        this.inTime = (chkDates == DateIsBetweenResult.Between);
        this.total = 0;
        this.totalQty = 0;
        this.reloadMaxTickets();
        // this.isLoading = false;
      })
  }

  private refreshAvailability() {
    this.ticketsService.getAvailabilityV2(this.communityId, this.ticket.id)
      .subscribe(({ freePlaces, groups }) => {
        this.availableTickets = freePlaces;
        this.availableByPrice = Helpers.ConvertListToDict(groups, 'groupId');
        this.maxPerPrice = this.setMaxPerPrice();
        this.reloadMaxTickets();
        this.isLoading = false;
        console.log('finisheds')
        this.onAvailabilityChanged.emit(this.availableTickets);
        this.swiperLoaded.emit(true);
      });
  }

  private reloadMaxTickets() {
    if (this.ticket?.maxByUser) {
      this.maxTiquetsPerUser = this.ticket.maxByUser - (this.totalQty + this.totalQtyPurchases);
    }

    if (this.availableTickets != null){
      if (this.maxTiquetsPerUser == null || this.availableTickets < this.maxTiquetsPerUser){
        this.maxTiquetsPerUser = this.availableTickets;
      }
    }
  }

  private setMaxPerPrice() {
    const dict = {};

    for (const price of this.ticket.prices) {
      const freePlaces = this.availableByPrice[price.id]?.freePlaces ?? 99999;
      const maxByUser = this.ticket.maxByUser ?? 99999;

      if (freePlaces < maxByUser) {
        dict[price.id] = freePlaces;
      } else {
        dict[price.id] = maxByUser;
      }
    }

    return dict;
  }
}
