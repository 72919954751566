export class InscriptionsConstants {
    public static OpenStatuses = {
        OPEN: "OPEN",
        FUTURE: "FUTURE",
        PAST: "PAST",
    };
    public static RecurrencesTypes = {
        OneTime: "OneTime",
        Monthly: "Monthly",
        Quarterly: "Quarterly",
        Yearly: "Yearly"
    };
  }
export namespace PermissionsConstans {
    export enum Permission {
        PUBLIC,
        PRIVATE
    }
}
