export namespace CustomFieldsConstants {
  export enum InputTypes {
    TEXT = "TEXT",
    TEXTAREA = "TEXTAREA",
    WYSIWYG = "WYSIWYG",
    NUMERIC = "NUMERIC",
    DATE = "DATE",
    DATETIME = "DATETIME",
    CHECKBOX = "CHECKBOX",
    DROPDOWN = "DROPDOWN",
    IMG = "IMG",
    FILE = "FILE",
    IBAN = "IBAN",
    PHONE = "PHONE",
    DNI = "DNI",
    EMAIL = 'EMAIL'
  };

  export enum DropdownTypes {
    CHIPS = "CHIPS",
    TABLE = "TABLE"
  };

  export enum Permissions {
    READ_WRITE = 'rw'
  }
}
