
<ng-container *ngIf="!readonly">
  <p-calendar 
    class="size-s"
    [showIcon]="true" 
    dateFormat="dd/mm/yy" 
    [(ngModel)]="internalValue" 
    (ngModelChange)="updateChanges($event)"
    [showTime]="hasTime"
    hourFormat="24"
    styleClass="w-100"
  ></p-calendar>
</ng-container>

<ng-container *ngIf="readonly">
  <span>{{ internalValue | date: dateFormat }}</span>
</ng-container>