import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { InputTextareaModule } from "primeng/inputtextarea";
import { CommonModule } from "@angular/common";

@Component({
  selector: 'app-custom-field-textarea',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFieldTextareaComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomFieldTextareaComponent),
      multi: true
    }
  ],
  templateUrl: './textarea.component.html',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextareaModule,
],
  styleUrls: ['../../styles/fields.scss', './textarea.component.scss']
})
export class CustomFieldTextareaComponent implements OnInit, ControlValueAccessor {

  @Input()
  public readonly: boolean;

  public internalValue: string;

  onChange: (_: string) => void = (_: string) => { };

  constructor() { }

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(): void { }

  writeValue(val: string): void {
    this.internalValue = val;
  }

  validate() {
    return false;
  }

  updateChanges(value: any) {
    this.onChange(value);
  }
}
